import React from 'react';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import ZmccRadioButton from 'library/common/commonComponents/FormComponent/Optimized/ZMCCRadioButton';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Button from 'library/common/commonComponents/Button/Button';
import { useXBApplicationSampleDetails } from './XBApplicationSampleDetails.hook';
import { xbMainApplicationChecklist } from './XBApplicationSampleDetails.constants';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation.component';
import { EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { ZMCC_CREATED } from 'library/common/commonConstants/storeConstants';

export const XBApplicationSamplesDetails = (props) =>{
    const { formValue, user, editCompletedBy } = props;
  const { xbMainApplicationChecklistForm, xbSampleDeatilsFrom, kitAttachments } = formValue;
  const { handleForm, handleNonFormComponent, xbMainApplicationModel, onEditCompleted } = useXBApplicationSampleDetails(props);
  
  const { zmccTransactionDetails } = props;
  const isBookingCompleted = zmccTransactionDetails && zmccTransactionDetails.zmccSavedStatus=== ZMCC_CREATED ? true : false;
  
  const isDisSolvedCheckbox =
  xbSampleDeatilsFrom &&
  xbSampleDeatilsFrom.formData &&
  (xbSampleDeatilsFrom.formData.xbStatusOfSampleId === 1 || xbSampleDeatilsFrom.formData.xbStatusOfSampleId === 2);
  return (
    <>
      <h2 className='mt-4 zmcc-title-heading'>{strings.mainApplicationChecklist.toUpperCase()}</h2>
      <p className='mt-3' style={{ whiteSpace: 'break-spaces' }}>
        {strings.subHeading}
      </p>
      <div className='mt-3'>
        <FormComponent
          model={xbMainApplicationModel()}
          formName='xbMainApplicationChecklistForm'
          formValue={xbMainApplicationChecklistForm.formData}
          onChange={handleForm}
        />
      </div>
      <h2 className='zmcc-title-heading mt-3'>{strings.smapleInformation}</h2>
      <div className='p-2 section-border'>
      <div className='col-sm-12 p-0'>
        <InputField
          label={strings.xbDetailedDescription}
          field='detailedDescription'
          formName='xbSampleDeatilsFrom'
          placeholder=''
          isRequired={true}
          type='text'
          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
          value={xbSampleDeatilsFrom.formData.detailedDescription}
          error={xbSampleDeatilsFrom.formData.detailedDescriptionError}
          onChange={e => handleNonFormComponent(e, 'detailedDescription', 'xbSampleDeatilsFrom')}
          bigSizeInput={'bigger-input-field'}
        />
      </div>
     { xbMainApplicationChecklistForm.formData.xbDoYouHaveDataFromCustomer === 'yes' &&<>
        <div className='d-flex'>
      <p className='mt-4'>{strings.zmccDocumentLink}</p>
      <Button
        isGhost={true}
        bgFill={false}
        dataTest='transaction-details-show-messages'
        styleClass='show-all-messages-button ml-2'
        iconPosition='left'
        onClick={() => props.downloadZmccDocumentFolder()}
        value={'ZMCC Documents'}
      />
      </div>
      
      <div className='col-sm-12 m-0 p-0 '>
        <Attachments
          text='Attach File'
          id='kitAttachmentsId'
          formValue={formValue && formValue}
          hasVideo={true}
          handleForm={props.updateSEMForm}
          actionFrom='zmccAttachement'
          isDownloadable
          downloadStream
          downloadIdField='kitAttachmentsId'
          isUploadAllowed={false}
          newVersionUi={true}
          uploaderStyle={"height-increased-dropzone"}
        />
      </div>
     </>}
      <div className='row m-0'>
        <div className='col-sm-4 mt-3 p-0 '>
          <Dropdown
            label={strings.xbStatusSamples}
            formName='xbSampleDeatilsFrom'
            placeholder='Please select'
            idKey='id'
            field='xbStatusOfSampleId'
            data={props.statusOfSamples || []}
            hasSection={false}
            multiSelect={false}
            filter={false}
            isRequired={true}
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            displayValue={[{ key: 'name', separator: '' }]}
            onChange={e => handleNonFormComponent(e, 'xbStatusOfSampleId', 'xbSampleDeatilsFrom')}
            value={xbSampleDeatilsFrom.formData.xbStatusOfSampleId}
            error={xbSampleDeatilsFrom.formData.xbStatusOfSampleIdError}
            fullValue={props.statusOfSamples.find(item => item.id === xbSampleDeatilsFrom.formData.xbStatusOfSampleId)}
          />
        </div>
        <div className='col-sm-2'></div>
        <div className='col-sm-4 mt-3'>
          <ZmccRadioButton
            label={strings.manipulateSamples}
            formName='xbSampleDeatilsFrom'
            value={xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImaging}
            error={xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingError}
            placeholder=''
            field='xbManipulateTheSamplesPriorToImaging'
            validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
            required={false}
            data={[
              {
                id: 'yes',
                value: 'yes',
              },
              {
                id: 'no',
                value: 'no',
              },
            ]}
            onChange={e => handleNonFormComponent(e, 'xbManipulateTheSamplesPriorToImaging', 'xbSampleDeatilsFrom')}
            isRequired={true}
          />
        </div>
      </div>
      <div className='row col-sm-12 mt-3'>
        {isDisSolvedCheckbox && isBookingCompleted && (
          <div className='col-sm-1 p-0 mt-4'>
            <Checkbox
              label={strings.solvedBy}
              field='statusOfSampleSolvedXb'
              isRequired={false}
              selected={xbSampleDeatilsFrom.formData.statusOfSampleSolvedXb}
              onChange={e => handleNonFormComponent(e, 'statusOfSampleSolvedXb', 'xbSampleDeatilsFrom')}
              newuiVersionStyle={true}
            />
          </div>
        )}
        {isDisSolvedCheckbox &&
          (xbSampleDeatilsFrom.formData.statusOfSampleSolvedXb) && (
            <>
             {!editCompletedBy && <div className='solved-by-edit'>
            {xbSampleDeatilsFrom.formData.completedByNameXb ? <p className=' mt-3 ml-4'>{xbSampleDeatilsFrom.formData.completedByNameXb}</p> : <p className=' mt-3 ml-4'>{`${user.firstName} ${user.lastName}`}</p>}
           <div className=' mt-3' onClick={() => onEditCompleted()}><EditOutlineIcon/></div>
            </div>}
            {editCompletedBy && <div className='col-sm-3 p-0 mt-3 ml-4'>
              <InputField
                label={''}
                field='completedByNameXb'
                formName='sampleDeatilsFrom'
                placeholder=''
                isRequired={false}
                type='text'
                validators={[]}
                value={xbSampleDeatilsFrom.formData.completedByNameXb}
                error={xbSampleDeatilsFrom.formData.completedByNameXbError}
                onChange={e => handleNonFormComponent(e, 'completedByNameXb', 'xbSampleDeatilsFrom')}
              />
            </div>}
            </>
          )}

      </div>
      <div className='col-sm-12 p-0 mt-4'>
        <InputField
          label={strings.xbSamplesCantManipulated}
          field='sampleSpecificComments'
          formName='xbSampleDeatilsFrom'
          placeholder=''
          isRequired={true}
          type='text'
          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
          value={xbSampleDeatilsFrom.formData.sampleSpecificComments}
          error={xbSampleDeatilsFrom.formData.sampleSpecificCommentsError}
          onChange={e => handleNonFormComponent(e, 'sampleSpecificComments', 'xbSampleDeatilsFrom')}
          bigSizeInput={'bigger-input-field'}
        />
      </div>
      </div>
      
    </>)
}

export default XBApplicationSamplesDetails;