import moment from 'moment';

import store from 'main/store/configureStore';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { deleteMasterDataService, fetchMasterDataService, fetchMasterDataServicePost, deleteMasterDataServiceWithoutCurly } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import zeissInstance from 'library/utilities/AxiosInstance';
import { getStockFullValueByIds, getCategoryFullValueByIds } from 'library/utilities/getFullValueByIds';
import { sortDates } from 'library/utilities/sort';
import { fetchFromStorage, saveToStorage} from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchStocksForCurrentUserService, checkBookingDatesAvailabilityService } from './calendar-services';
import {
  ADD_BOOKING,
  FETCH_AVAILABLE_KIT_LIST,
  FETCH_OPERATORS_LIST,
  FETCH_STOCKS_FOR_USERS,
  UPDATE_FILTERS,
  UPDATE_LABS_LOCATION_FILTERS,
  UPDATE_ZMCC_FILTERS,
  UPDATE_ZMCC_DATE_FILTERS,
  CLEAR_ZMCC_EXISTING_DATA,
  SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES,
  FETCH_IQS_COMMENT_LOCATIONS,
  IQS_ADDED_COMMENT_LIST,
} from './calendarConstants';
import { scrollToTop } from 'library/utilities/scrollActions';
import strings from 'resources/locales/Translate';
import { ZMCC_EVENT_DEFAULTS } from 'library/utilities/constants';
import { sortBy } from 'lodash';

const REGULAR_CALENDAR = 1;
const LAB_LOCATION_CALENDAR = 2;

export const addBookings = (item, isLabsLocation) => dispatch => {
  const calendarState = store.getState().calendar;
  const updatedBookings = [...calendarState.bookings];
  updatedBookings.push({ ...item, isLabsLocation });

  dispatch({
    type: ADD_BOOKING,
    payload: updatedBookings,
  });
};

export const removeBooking = items => dispatch => {
  const calendarState = store.getState().calendar;
  const updatedBookings = [...calendarState.bookings];

  items.forEach(item => {
    const index = updatedBookings.findIndex(booking => booking.id === item.id);
    if (index !== -1) {
      updatedBookings.splice(index, 1);
    }
  });

  dispatch({
    type: ADD_BOOKING,
    payload: updatedBookings,
  });
};

export const sendDataGraphql =
'kitReparation[isReparationTimeActive,reparationFrom,reparationTo,reparationComment],kitName,kitInformationId,kitCondition[kitConditionId],serialNumber,stockInformation[id,email,locationShortName,businessUnit[name,designation],businessUnitId,country[shortName],userRole[accountId,role[role]],workflowCategoryAnswers[workflowCategoryQuestions[workflowCategoryQuestionsId],value]],deliveryDays,returnDeliveryDays,reconditioningDeliveryDays,maxLoanDuration,availabilityFrom,availabilityTo,transactionsForCalendar[organization,borrowerFname,borrowerLname,customerFname,customerLname,stockControllerFname,stockControllerLname,transactionsId,targetDeliveryFrom,targetDeliveryTo,targetReconditioningFrom,targetReconditioningTo,twentyFourHourReservation,targetReservationFrom,returnDelivery,targetReservationTo,targetReturnDeliveryFrom,targetReturnDeliveryTo,checkedDate,targetCheckedDate,deliveryComment,comment,nextC2cId,prevC2cId,deliveryDate,reservationFrom,reservationTo,state,city,customer,transactionStatusId,businessUnitDesignation,countryShortName,transactionNumber],dateOfManufacture,itemNumber,specialDeliveryTime[deliveryDays,returnDeliveryDays,countryId],roomGroupName,zmccCalendarBlocked[id,zmccCalendarBlockStatus,from,to],zmccCalendarBooked[prePreparationPeriod,postPreparationPeriod,transactionsId,transactionNumber,from,to,kitInformationId,zmccCalendarBlockId,eventStartTime,eventEndTime,name,status,intendedFor,customerName,customerInstitute,transactionStatusId,intendedForId,priority,priorityId,scheduleId,scheduledDate,scheduleStartDate,scheduleEndDate,operatorFirstName,operatorLastName,operators,semBooking,xrmBooking,lmBooking,xbBooking,noTypeBooking,intendedForStaticValue],warehouseSlot[startDate,endDate,recurring,daysSelected]';

export const handlingSendData = (data, location, isCalendar = false) => {
  const isLabsLocation = location && location.pathname && location.pathname.toLowerCase().includes('labs-location-calendar');
  const kitStatus = isLabsLocation ?
    ['kitStatus.labAndLocation:ne:14', 'or', 'kitStatus.labAndLocation:ne:15'] :
    [];

  const filter = [
    [
      [
        kitStatus,
        'and',
        'deleted:eq:false',
        'and',
        'kitName:nn:',
        'and',
        [
          'availabilityTo:goe:' + moment().format('YYYY-MM-DD HH:mm:ss'),
          'or',
          ['availabilityTo:em:', 'or', 'availabilityTo:nl:'],
        ],
        'and',
        ['kitCondition.id:ne:4', 'and', 'kitCondition.id:ne:1', 'and', 'kitCondition.id:ne:7',
          'and', 'kitCondition.id:ne:8', 'and', 'kitCondition.id:ne:9', 'or', 'kitCondition.id:nl:0'],
      ],
    ],
  ];
  const {
    filter: { stock, category, kitId , systemCategory},
    searchTerm,
  } = data;

  const sort = [];
  if (data && data['sort']) {
    const {
      sort: { kitName, serialNumber, dateOfManufacture, itemNumber },
    } = data;

    if (kitName){
      sort.push('kitName');
      sort.push(`${kitName}`);
    }
    else if(serialNumber) {
      sort.push('serialNumber');
      sort.push(`${serialNumber}`);
    }
    else if(itemNumber) {
      sort.push('itemNumber');
      sort.push(`${itemNumber}`);
    }
    else if(dateOfManufacture){
      sort.push('dateOfManufacture');
      sort.push(`${dateOfManufacture}`);
    }
  }
  const sendData = { ...data };

  if (stock && stock.length) {
    let stockFilterArray = [];
    if (filter.length) filter.push('and');

    stock.forEach((item, index) => {
      if (index !== 0) stockFilterArray.push('or');
      stockFilterArray.push(`stock.id:eq:${item}`);
    });
    filter.push(stockFilterArray);
  }

  if (systemCategory && systemCategory.length) {
    let systemCategoryFilterArray = [];
    if (filter.length) filter.push('and');

    systemCategory.forEach((item, index) => {
      if (index !== 0) systemCategoryFilterArray.push('or');
      systemCategoryFilterArray.push(`zmccSystemClass.zmccSystemCategoryId:eq:${item}`);
    });
    filter.push(systemCategoryFilterArray);
  }

  if (category && category.length) {
    let categoryFilter = [];
    if (filter.length) filter.push('and');

    category.forEach((item, index) => {
      if (index !== 0) categoryFilter.push('or');
      categoryFilter.push(`systemMainComponent.id:eq:${item}`);
    });

    filter.push(categoryFilter);
  }
  if (searchTerm) {
    if (filter.length) filter.push('and');
    let searchArray = [];
    searchArray.push(`kitName:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`stock.name:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`serialNumber:cic:${searchTerm}`);
    filter.push(searchArray);
  }
  if (kitId) {
    if (filter.length) filter[0][0].push('and');
    filter[0][0].push(`id:eq:${kitId}`);
  }

  if(data?.filter?.multipleKits?.length > 0){

    let filterArray = [];
    data.filter.multipleKits.forEach((kitId, index) => {
      if(index !== data.filter.multipleKits.length - 1){
        filterArray.push(`id:eq:${kitId}`)
        filterArray.push("or")
      }else{
        filterArray.push(`id:eq:${kitId}`)
      }
    })
    if (filter.length) filter[0][0].push('and');
      filter[0][0].push(filterArray);
  }

  if(data?.filter?.searchTermIds?.length){
    let filterArray = [];
    data.filter.searchTermIds.forEach((id, index) => {
      if(index !== data.filter.searchTermIds.length - 1){
        filterArray.push(`searchTerm.id:eq:${id}`)
        filterArray.push("or")
      }else{
        filterArray.push(`searchTerm.id:eq:${id}`)
      }
    })
    if (filter.length){
      filter[0][0].push('and');
      filter[0][0].push(filterArray);
    }
  }
  
  sendData.isCustomFilterApplied = isCalendar;
  sendData.filter = filter;
  sendData.sort = sort;
  sendData.graphql = sendDataGraphql;

  return sendData;
};

export const checkCalendarFilters = (data, location, isInitial) => async (dispatch, getState) => {
  const isLabsLocation = location.pathname.toLowerCase().includes('labs-location-calendar');
  const isZmccCalendar = location.pathname.toLowerCase().includes('zmcc-calendar');
  const isIqsCalendar = location.pathname.toLowerCase().includes('iqs-calendar');
  const isCalendar = true;
  let { countries } = getState().masterDataReducer;
  if (isZmccCalendar){
    isInitial = 'isInitial'
  }
  try {
    let sendData;
    if (isInitial) {
      let newData;
      let filterFromHomePage;
      let savedCalendarFilter;

      let hasCalendarFilter = location && location.state && (
        location.state.loanType ||
        (location.state.stocks && location.state.stocks.length) ||
        (location.state.systemClass && location.state.systemClass.length))

      if (location && location.state && (
        location.state.loanType ||
        (location.state.stocks && location.state.stocks.length) ||
        (location.state.systemClass && location.state.systemClass.length)
      )) {
        filterFromHomePage = {
          loanType: 1,
          loanTypeFullValue: { name: 'Demo loan', id: 1 },
          category: location.state.systemClass,
          categoryFullValue: location.state.systemClassFullValue,
          stock: location.state.stocks,
          loanFromDate: location.state.loanFromDate,
          loanEndDate: location.state.loanToDate,
          stockFullValue: getStockFullValueByIds(location.state.stocks, countries),
        };
        savedCalendarFilter = await dispatch(fetchCalendarFilters(isLabsLocation));
        dispatch({
          type: UPDATE_FILTERS,
          payload: { ...filterFromHomePage },
        });
      } else if (isLabsLocation && !isZmccCalendar) {
        savedCalendarFilter = await dispatch(fetchCalendarFilters(isLabsLocation));
      } else if (isZmccCalendar){
        filterFromHomePage = {
          loanType: 1,
          loanTypeFullValue: { name: 'Demo loan', id: 1 },
          category: data.filter.category,
          categoryFullValue: data.filter.categoryFullValue,
          stock: data.filter.stock,
          stockFullValue: data.filter.stockFullValue,
          systemCategory: data.filter.systemCategory,
          systemCategoryFullValue: data.filter.systemCategoryFullValue,
        };
        dispatch({
          type: UPDATE_ZMCC_FILTERS,
          payload: { ...filterFromHomePage },
        })
      }
      if(!isZmccCalendar && !isLabsLocation && hasCalendarFilter){
        savedCalendarFilter = filterFromHomePage
      }else if(isLabsLocation && hasCalendarFilter){
        filterFromHomePage = {
          loanType: 1,
          loanTypeFullValue: { name: 'Demo loan', id: 1 },
          category: location.state.systemClass,
          categoryFullValue: location.state.systemClassFullValue,
          stock: location.state.stocks,
          loanFromDate: location.state.loanFromDate,
          loanEndDate: location.state.loanToDate,
          stockFullValue: getStockFullValueByIds(location.state.stocks, countries),
        };
        savedCalendarFilter = filterFromHomePage
        dispatch({
          type: UPDATE_FILTERS,
          payload: { ...filterFromHomePage },
        });
      }else if (!isZmccCalendar && !isIqsCalendar){
        savedCalendarFilter = await dispatch(fetchCalendarFilters(isLabsLocation));
      }
      newData = !isZmccCalendar ? { ...data, filter: savedCalendarFilter } : filterFromHomePage ? { ...data, filter: filterFromHomePage } : { ...data, filter: savedCalendarFilter };
      sendData = handlingSendData(newData, location, isCalendar);
    } else {
      sendData = handlingSendData(data, location, isCalendar);
    }

    return sendData;
  } catch (error) {
    return handlingSendData(data, location, isCalendar);
  }
};

export const updateZmccDatesFilters = (payload) => dispatch => {
  dispatch({type: UPDATE_ZMCC_DATE_FILTERS, payload})
}

export const createOperatorEvents = (operatorSchedules) => {
  if(!operatorSchedules){
    return []
  }
  const existingOperators = store.getState().calendar.operatorsList;
  let operators = [];
  operatorSchedules.forEach(operator => {
    let currentOperator = existingOperators.find(existingOperator => existingOperator.accountId === operator.accountId)
    if(currentOperator){
      operators.push({
        ...currentOperator,
        ...operator,
        schedules: {...currentOperator.schedules, ...operator.schedules}
      })
    }else{
      operators.push(operator)
    }
  });
  return operators;
}

export const clearZmccDataOnFiltersApplied = () => {
  store.dispatch({type: CLEAR_ZMCC_EXISTING_DATA})
}

export const fetchAvailableKitList = (data, location, isInitial, zmccMonthsFilters={} ) => async dispatch => {
  
  let zmccFetchFromDate = zmccMonthsFilters?.zmccFetchFromDate // This is date from which fetch should start
  let zmccFetchToDate = zmccMonthsFilters?.zmccFetchToDate // date to which fetch should end(these two are generated based on user clicks on prev and next buttons)
  let filtersApplied = zmccMonthsFilters?.filtersApplied // This flag is used to clear existing data from redux

  const isLabsLocation = location.pathname.toLowerCase().includes('labs-location-calendar');
  const isZmccCalendar = location.pathname.toLowerCase().includes('zmcc-calendar');
  let url = isLabsLocation ? URLS.labLocationСalendar : isZmccCalendar ? URLS.operatorsForZmccCalendar : URLS.kitsList;
  if (isZmccCalendar) {
    url = `${url}?calendarSearch=true`
  }else{
   url = `${url}?calendarSearch=true&startRange=${moment()
      .subtract(12, 'months')
      .valueOf()}&endRange=${moment().add(30, 'months').valueOf()}`
  }
  if(filtersApplied && isZmccCalendar){
    isInitial = true // Made true as kit-no-longer-available slots are prepared (zmcc) based on this
    clearZmccDataOnFiltersApplied();
  }

  if(isInitial && isZmccCalendar){
    let prevMonth = moment().subtract(1, 'months').startOf('month')
    let nextMonth = moment().add(1, 'months').endOf('month')
    let currentMonth = moment().startOf("month").format("YYYY-MM")
    dispatch({type: UPDATE_ZMCC_DATE_FILTERS, payload: new Set([currentMonth, prevMonth.format("YYYY-MM"), nextMonth.format("YYYY-MM")])})
    url = `${URLS.operatorsForZmccCalendar}?calendarSearch=true&fromDate=${prevMonth.format("YYYY-MM-DD")}&toDate=${nextMonth.format("YYYY-MM-DD")}`;
  }else if(zmccFetchFromDate && zmccFetchToDate && moment(zmccFetchFromDate).isValid() && moment(zmccFetchToDate).isValid() && isZmccCalendar){
    url = `${URLS.operatorsForZmccCalendar}?calendarSearch=true&fromDate=${zmccFetchFromDate}&toDate=${zmccFetchToDate}`;
  }
  const sendData = await dispatch(checkCalendarFilters(data, location, isInitial));
  return zeissInstance
    .post(
     url,
      sendData,
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_AVAILABLE_KIT_LIST,
          payload: {
            kits: createData(res.data),
            alreadyBooked: createAlreadyBookedData(res.data.content, isInitial),
            isZmccCalendar
          },
        });
        if (isZmccCalendar) {
          dispatch({type:FETCH_OPERATORS_LIST, payload: createOperatorEvents(res.data.operatorSchedules)});
        }
      } else {
        dispatch({
          type: FETCH_AVAILABLE_KIT_LIST,
          payload: {
            kits: [],
            alreadyBooked: [],
          },
        });
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_AVAILABLE_KIT_LIST,
        payload: {
          kits: [],
          alreadyBooked: [],
        },
      });
    });
};

export const createData = data => {
  const newData = { ...data };
  let arrayOfContent = [];
  if (newData && newData.content && newData.content.length) {
    newData.content.forEach(item => {
      const itemObject = { ...item };
      itemObject['id'] = item.kitInformationId;
      itemObject['name'] = item.kitName;
      arrayOfContent.push(itemObject);
    });
    // Defective kits only visible for warehouse managr and dispatcher
    let filteredKits = arrayOfContent.filter((item, index) => {
      if(item?.kitCondition?.kitConditionId === 2){ //defective kit
        let stockInformation = item?.stockInformation;
        if(!stockInformation){
          return false
        }
         let role = stockInformation?.userRole && stockInformation?.userRole?.length && stockInformation.userRole[0].role.role.toLowerCase() ;
        return role === 'stock controller' || role === 'dispatcher' || store.getState()?.authReducer?.user?.admin
      }else{
        return true
      }
    })
    newData['content'] = filteredKits;
    // newData['content'] = arrayOfContent;
    }
  return newData;
};

export function createAlreadyBookedData(kits, isInitial = false) {
  let alreadyBooked = [];
  kits.forEach(kit => {
    let { kitInformationId, kitCondition, transactionsForCalendar, kitReparation, availabilityTo, stockInformation, zmccCalendarBlocked, zmccCalendarBooked, warehouseSlot,availabilityFrom} = kit;
    const isLabsLocation = stockInformation && stockInformation.businessUnit && stockInformation.businessUnit.name === 'RMS labs@location Partner';
    const isZmccStock = stockInformation && stockInformation.businessUnit && stockInformation.businessUnit.designation === "RMS_ZMCC";
    if (kitCondition && kitCondition.kitConditionId && kitReparation && kitReparation.length) {
      const refurbishmentPeriod = kitReparation.map(item =>({
        kit,
        title: '',
        id: kitInformationId,
        resourceId: parseInt(kitInformationId),
        start: moment.utc(item.reparationFrom).isValid() && `${moment.utc(item.reparationFrom).format('YYYY-MM-DD')} 00:00:00`,
        end: moment.utc(item.reparationTo).isValid() && `${moment.utc(item.reparationTo).format('YYYY-MM-DD')} 23:59:00`,
        reconditioningDays:
          moment(`${moment.utc(item.reparationTo).format('YYYY-MM-DD')} 12:00`).diff(
            moment(`${moment.utc(item.reparationFrom).format('YYYY-MM-DD')} 12:00`),
            'days',
          ) + 1,
        isRefurbishment: true,
        showPopover: true,
        isZMCCCalendar : isZmccStock ? true : false
      }));
      refurbishmentPeriod.map(item =>{
        alreadyBooked.push(item);
      });

    }
    if (availabilityTo && isInitial) {
      const startBlockedDate = moment.utc(availabilityTo).isValid() && `${moment.utc(availabilityTo).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`;
      const endBlockedDate = moment.utc(availabilityTo).isValid() && `${moment.utc(availabilityTo).add(1, 'days').add(6, 'months').format('YYYY-MM-DD')} 23:59:00`;
      const blockedPeriod = {
        kit,
        title: '',
        id: kitInformationId,
        resourceId: parseInt(kitInformationId),
        start: startBlockedDate,
        end: endBlockedDate,
        blockedDays: moment(endBlockedDate).diff(moment(startBlockedDate), 'days') + 1,
        isBlocked: true,
        showPopover: true,
      };
      if(isInitial || !isZmccStock) {// temporary fix
        alreadyBooked.push(blockedPeriod);
      }
    }else if(!availabilityTo && !warehouseSlot && isZmccStock){
      const startBlockedDate = moment.utc(availabilityFrom).isValid() && `${moment.utc(availabilityFrom).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`;
      const endBlockedDate = moment.utc(availabilityFrom).isValid() && `${moment.utc(availabilityFrom).add(1, 'days').add(12, 'months').format('YYYY-MM-DD')} 23:59:00`;
      const blockedPeriod = {
        kit,
        title: '',
        id: kitInformationId,
        resourceId: parseInt(kitInformationId),
        start: startBlockedDate,
        end: endBlockedDate,
        blockedDays: moment(endBlockedDate).diff(moment(startBlockedDate), 'days') + 1,
        isBlocked: true,
        showPopover: true,
        isSlotNotAvailable: true
      };
      if(isInitial || !isZmccStock) {// temporary fix
        alreadyBooked.push(blockedPeriod);
      }
    }
    if(isZmccStock){
    if (warehouseSlot && isInitial) { // temparory fix
      if(stockInformation?.userRole && stockInformation?.userRole?.length && stockInformation.userRole[0].role.role.toLowerCase() !==  'stock controller' ){
        const startWhSlotDate = moment.utc(warehouseSlot.endDate).isValid() && `${moment.utc(warehouseSlot.endDate).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`;
        let endWhSlotDate = moment.utc(availabilityTo).isValid() && `${moment.utc(availabilityTo).format('YYYY-MM-DD')} 23:59:00`;
        if(!availabilityTo){
          endWhSlotDate = moment.utc(warehouseSlot.endDate).isValid() && `${moment.utc(warehouseSlot.endDate).add(1, "day").add(12, "months").format('YYYY-MM-DD')} 23:59:00`;
        }

        const blockedPeriod = {
          kit,
          title: '',
          id: kitInformationId,
          resourceId: parseInt(kitInformationId),
          start: startWhSlotDate,
          end: endWhSlotDate,
          blockedDays: moment(endWhSlotDate).diff(moment(startWhSlotDate), 'days') + 1,
          isBlocked: true,
          showPopover: true,
          isSlotNotAvailable: true,
        };
        alreadyBooked.push(blockedPeriod);

        const startWhSKitStartDate = moment.utc(availabilityFrom).isValid() && `${moment.utc(availabilityFrom).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`;
        const endWhSlotStartDate = moment.utc(warehouseSlot.startDate).isValid() && `${moment.utc(warehouseSlot.startDate).subtract(1, 'day').format('YYYY-MM-DD')} 23:59:00`;

        const blockedPeriodFromKitStart = {
          kit,
          title: '',
          id: kitInformationId,
          resourceId: parseInt(kitInformationId),
          start: startWhSKitStartDate,
          end: endWhSlotStartDate,
          blockedDays: moment(endWhSlotStartDate).diff(moment(startWhSKitStartDate), 'days') + 1,
          isBlocked: true,
          showPopover: true,
          isSlotNotAvailable: true
        };
        alreadyBooked.push(blockedPeriodFromKitStart);

      // Function to check if a date is blocked or booked
      const isDateBlocked = (date, blockedList) => {
        if(blockedList && blockedList.length >0 ){
          return blockedList.some(period => date.isBetween(moment(period.from), moment(period.to), null, '[]'))
        } else {
          return false;
        }
      }


      const isDateBooked = (date, bookedList) => {
        if(bookedList && bookedList.length >0 ){
          return bookedList.some(period => date.isBetween(moment(period.from), moment(period.to), null, '[]'));
        } else {
          return false;
        }
      }

        const startDateOfWhslot = warehouseSlot.startDate;
        const endDateOfWhSlot = warehouseSlot.endDate;

        const availableDays = warehouseSlot.daysSelected;

        let currentStart = moment(startDateOfWhslot);
        const finalEnd = moment(endDateOfWhSlot);

        const blockedPeriods = [];
        let blockStart = null;

        while (currentStart.isSameOrBefore(finalEnd)) {
          if (isDateBlocked(currentStart, zmccCalendarBlocked) || isDateBooked(currentStart, zmccCalendarBooked)) {
            // If there is an ongoing blocked period, end it
            if (blockStart) {
              let blockEnd = currentStart.clone().subtract(1, 'day').endOf('day');
              blockedPeriods.push({
                  kit,
                  title: '',
                  id: kitInformationId,
                  resourceId: parseInt(kitInformationId),
                  start: blockStart.valueOf(),
                  end: blockEnd.valueOf(),
                  blockedDays: blockEnd.diff(blockStart, 'days') + 1,
                  isBlocked: true,
                  showPopover: true,
                  isSlotNotAvailable: true
              });
              blockStart = null;
          }
          currentStart.add(1, 'day');
          continue;
        }
          if (availableDays && !availableDays.includes(currentStart.format('dddd'))) {
              if (!blockStart) {
                  blockStart = currentStart.clone();
              }
          } else {
              if (blockStart) {
                  let blockEnd = currentStart.clone().subtract(1, 'day').endOf('day');
                  blockedPeriods.push({
                      kit,
                      title: '',
                      id: kitInformationId,
                      resourceId: parseInt(kitInformationId),
                      start: blockStart.valueOf(),
                      end: blockEnd.valueOf(),
                      blockedDays: blockEnd.diff(blockStart, 'days') + 1,
                      isBlocked: true,
                      showPopover: true,
                      isSlotNotAvailable: true
                  });
                  blockStart = null;
              }
          }
          currentStart.add(1, 'day');
      }
      if (blockStart && moment(blockStart).format("DD-MM-YYYY") !== moment(availabilityTo).format("DD-MM-YYYY")) {
        let blockEnd;
        if (!availableDays.includes(finalEnd.format('dddd'))) {
            blockEnd = finalEnd.clone().endOf('day');
        } else {
            blockEnd = finalEnd.clone().subtract(1, 'day').endOf('day');
        }
        blockedPeriods.push({
            kit,
            title: '',
            id: kitInformationId,
            resourceId: parseInt(kitInformationId),
            start: blockStart.valueOf(),
            end: blockEnd.valueOf(),
            blockedDays: blockEnd.diff(blockStart, 'days') + 1,
            isBlocked: true,
            showPopover: true,
            isSlotNotAvailable: true
        });
      }

      alreadyBooked.push(...blockedPeriods);
      }
  } else{
    const startBlockedDate = moment.utc(availabilityFrom).isValid() && `${moment.utc(availabilityFrom).add(1, 'days').format('YYYY-MM-DD')} 00:00:00`;
    const endBlockedDate = moment.utc(availabilityTo).isValid() && `${moment.utc(availabilityTo).format('YYYY-MM-DD')} 23:59:00`;
    const blockedPeriod = {
      kit,
      title: '',
      id: kitInformationId,
      resourceId: parseInt(kitInformationId),
      start: startBlockedDate,
      end: endBlockedDate,
      blockedDays: moment(endBlockedDate).diff(moment(startBlockedDate), 'days') + 1,
      isBlocked: true,
      showPopover: true,
      isSlotNotAvailable: true
    };
    if(isInitial) {// temporary fix
      alreadyBooked.push(blockedPeriod);
    }
  }
}

    if (isZmccStock) {
      if (zmccCalendarBooked && zmccCalendarBooked.length>0){
        zmccCalendarBooked.forEach((transaction, index) => {
          const {
            from,
            to,
            id,
            transactionsId,
            zmccCalendarBlockId,
            eventStartTime,
            eventEndTime,
            intendedFor,
            operators,
            priority,
            name,
            customerName,
            customerInstitute,
            xbBooking,
            xrmBooking,
            lmBooking,
            noTypeBooking,
            semBooking,
            status,
            transactionStatusId,
            intendedForStaticValue,
            postPreparationPeriod,
            prePreparationPeriod,
          } = transaction;
          
          let slotStart = moment(from).local();
          let slotEnd = moment(to).local();
          slotStart = slotStart.subtract(prePreparationPeriod ? prePreparationPeriod: 0, 'days').startOf('day');
          slotEnd = slotEnd.add(postPreparationPeriod ? postPreparationPeriod: 0, 'days').endOf('day');

          let startDate;
          let endDate;

          if((eventStartTime != null && eventStartTime!= "00:00:00") && (eventEndTime != null && eventEndTime!= "23:59:00")){
            startDate = slotStart.format('YYYY-MM-DD')+ ` ${eventStartTime}` ;
            endDate = slotEnd.format('YYYY-MM-DD')+ ` ${eventEndTime}`;
          } else{
            startDate = slotStart.format('YYYY-MM-DD')+ " 00:00:00";
            endDate = slotEnd.format('YYYY-MM-DD')+ " 23:59:59";
          }

          const transactionObject = {
            kit,
            resourceId: parseInt(kitInformationId),
            start: startDate,
            end: endDate,
            isBooked: true,
            "isSelected": true,
            "isLabsLocation": false,
            "isZMCCCalendar": true,
            "transactionsId": transactionsId,
            "id": zmccCalendarBlockId,
            title: `${name !== null ? (eventStartTime && eventEndTime ? `${moment(eventStartTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(eventEndTime, 'HH:mm:ss').format('hh:mm A')}` : `${ZMCC_EVENT_DEFAULTS.FROM_TIME} - ${ZMCC_EVENT_DEFAULTS.TO_TIME}`) + " " + name  : ''}`,
            name: name,
            category: xbBooking ? 'XB' : xrmBooking ? 'XRM' : lmBooking ? 'LM' : semBooking ? 'SEM' : noTypeBooking ? 'NO Type' : '',
            priority: priority,
            customerName: customerName,
            customerInstitute : customerInstitute,
            operator: operators,
            status: status,
            transactionStatusId: transactionStatusId,
            intendedForStaticValue: intendedForStaticValue,
            postPreparationPeriod,
            prePreparationPeriod,
            suggestedDateFrom: moment(from).format('YYYY-MM-DD'),
            suggestedDateTo: moment(to).format('YYYY-MM-DD'),
          };

          alreadyBooked.push(transactionObject);

        })
      }

    }
    else {
      if (transactionsForCalendar && transactionsForCalendar.length > 0) {
        transactionsForCalendar.forEach((transaction, index) => {
          const {
            nextC2cId,
            prevC2cId,
            targetDeliveryFrom,
            targetDeliveryTo,
            twentyFourHourReservation,
            targetReservationFrom,
            targetReservationTo,
            targetReturnDeliveryFrom,
            targetReturnDeliveryTo,
            targetReconditioningTo,
            targetReconditioningFrom,
            transactionsId,
            borrowerFname,
            borrowerLname,
            customerFname,
            customerLname,
            stockControllerFname,
            stockControllerLname,
            checkedDate,
            comment,
            organization,
            deliveryDate,
            reservationFrom,
            reservationTo,
            returnDelivery,
            customer,
            city,
            state,
            transactionStatusId,
            businessUnitDesignation,
            countryShortName,
            transactionNumber
          } = transaction;

          let
          newTargetReturnDeliveryTo = targetReturnDeliveryTo,
          newTargetReconditioningFrom = targetReconditioningFrom,
          newTargetReconditioningTo = targetReconditioningTo,
          newTargetReturnDeliveryFrom = targetReturnDeliveryFrom,
          newEnd = targetReconditioningTo

          if (moment(targetReservationFrom).isAfter(moment().subtract(36, 'months'))) {
            if (twentyFourHourReservation) {
              if (targetReservationFrom && targetReservationTo) {
                const transactionObject = {
                  transactionsId,
                  kit,
                  title: '',
                  id: index + 1,
                  isBooked: true,
                  resourceId: parseInt(kitInformationId),
                  start: `${moment.utc(targetReservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReservationFrom:
                    targetReservationFrom &&
                    moment.utc(targetReservationFrom).isValid() &&
                    `${moment.utc(targetReservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReservationTo:
                    targetReservationTo &&
                    moment.utc(targetReservationTo).isValid() &&
                    `${moment.utc(targetReservationTo).format('YYYY-MM-DD')} 23:59:00`,
                  targetDeliveryFrom:
                    targetDeliveryFrom &&
                    moment.utc(targetDeliveryFrom).isValid() &&
                    `${moment.utc(targetDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetDeliveryTo:
                    targetDeliveryTo &&
                    moment.utc(targetDeliveryTo).isValid() &&
                    `${moment.utc(targetDeliveryTo).format('YYYY-MM-DD')} 23:59:00`,
                  targetReturnDeliveryFrom:
                    targetReturnDeliveryFrom &&
                    moment.utc(targetReturnDeliveryFrom).isValid() &&
                    `${moment.utc(targetReturnDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReturnDeliveryTo:
                    targetReturnDeliveryTo &&
                    moment.utc(targetReturnDeliveryTo).isValid() &&
                    `${moment.utc(targetReturnDeliveryTo).format('YYYY-MM-DD')} 23:59:00`,
                  targetReconditioningFrom:
                    targetReconditioningFrom &&
                    moment.utc(targetReconditioningFrom).isValid() &&
                    `${moment.utc(targetReconditioningFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReconditioningTo:
                    targetReconditioningTo &&
                    moment.utc(targetReconditioningTo).isValid() &&
                    `${moment.utc(targetReconditioningTo).format('YYYY-MM-DD')} 23:59:00`,
                  end: `${moment.utc(targetReservationTo).format('YYYY-MM-DD')} 23:59:00`,
                  isTwentyFourHourReservation: twentyFourHourReservation,
                  borrower: `${borrowerFname} ${borrowerLname}`,
                  customerName:
                    (customerFname || customerLname) &&
                    `${customerFname} ${customerLname}`,
                  stockController: `${stockControllerFname} ${stockControllerLname}`,
                  comment: comment || '',
                  organization,
                  city,
                  state,
                  transactionStatusId,
                  businessUnitDesignation,
                  countryShortName,
                  transactionNumber
                };

                alreadyBooked.push(transactionObject);
              }
            } else if (isLabsLocation) {
              const transactionObject = {
                transactionsId,
                kit,
                title: '',
                id: index + 1,
                isBooked: true,
                resourceId: parseInt(kitInformationId),
                start: `${moment.utc(targetReservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                targetReservationFrom:
                  targetReservationFrom &&
                  moment.utc(targetReservationFrom).isValid() &&
                  `${moment.utc(targetReservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                targetReservationTo:
                  targetReservationTo &&
                  moment.utc(targetReservationTo).isValid() &&
                  `${moment.utc(targetReservationTo).format('YYYY-MM-DD')} 23:59:00`,
                end: `${moment.utc(targetReservationTo).format('YYYY-MM-DD')} 23:59:00`,
                isTwentyFourHourReservation: twentyFourHourReservation,
                borrower: `${borrowerFname} ${borrowerLname}`,
                customerName: customer || '',
                stockController: `${stockControllerFname} ${stockControllerLname}`,
                comment: comment || '',
                organization,
                city,
                state,
                transactionStatusId,
                businessUnitDesignation,
                countryShortName,
                transactionNumber
              };

              alreadyBooked.push(transactionObject);
            }
            else if( nextC2cId!==null && deliveryDate && reservationFrom && reservationTo && returnDelivery){
               let transactionObject = {
                 transactionsId,
                 nextC2cId,
                 prevC2cId,
                 kit,
                 title: '',
                 id: index + 1,
                 isBooked: true,
                 ...calculateNumberOfDates({ transaction: transaction, kit }),
                 resourceId: parseInt(kitInformationId),
                 start: `${moment.utc(deliveryDate).format('YYYY-MM-DD')} 00:00:00`,
                 targetReservationFrom:
                   reservationFrom &&
                   moment.utc(reservationFrom).isValid() &&
                   `${moment.utc(reservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                 targetReservationTo:
                 reservationTo &&
                   moment.utc(reservationTo).isValid() &&
                   `${moment.utc(reservationTo).format('YYYY-MM-DD')} 23:59:00`,
                 targetDeliveryFrom:
                 deliveryDate &&
                   moment.utc(deliveryDate).isValid() &&
                   `${moment.utc(deliveryDate).format('YYYY-MM-DD')} 00:00:00`,
                 targetDeliveryTo:
                 returnDelivery &&
                   moment.utc(returnDelivery).isValid() &&
                   `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 23:59:00`,
                 targetReturnDeliveryFrom:
                 reservationTo &&
                   moment.utc(reservationTo).isValid() &&
                   `${moment.utc(reservationTo).format('YYYY-MM-DD')} 00:00:00`,
                 targetReturnDeliveryTo:
                 returnDelivery &&
                   moment.utc(returnDelivery).isValid() &&
                   `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 23:59:00`,
                 targetReconditioningFrom:
                 returnDelivery &&
                   moment.utc(returnDelivery).isValid() &&
                   `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
                 end: `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 23:59:00`,
                 isTwentyFourHourReservation: twentyFourHourReservation,
                 borrower: `${borrowerFname} ${borrowerLname}`,
                 customerName:
                   (customerFname || customerLname) &&
                   `${customerFname} ${customerLname}`,
                 stockController: `${stockControllerFname} ${stockControllerLname}`,
                 comment: comment || '',
                 organization,
                 city,
                 state,
                 transactionStatusId,
                 businessUnitDesignation,
                 countryShortName,
                 transactionNumber
               };
               transactionObject = {
                 ...transactionObject,
                 ...calculateCalendarDates(transactionObject),
               };

               alreadyBooked.push(transactionObject);

             }
            else if(deliveryDate && reservationFrom && reservationTo && returnDelivery && checkedDate){
              let transactionObject = {
                transactionsId,
                nextC2cId,
                prevC2cId,
                kit,
                title: '',
                id: index + 1,
                isBooked: true,
                ...calculateNumberOfDates({ transaction: transaction, kit }),
                resourceId: parseInt(kitInformationId),
                start: `${moment.utc(deliveryDate).format('YYYY-MM-DD')} 00:00:00`,
                targetReservationFrom:
                  reservationFrom &&
                  moment.utc(reservationFrom).isValid() &&
                  `${moment.utc(reservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                targetReservationTo:
                reservationTo &&
                  moment.utc(reservationTo).isValid() &&
                  `${moment.utc(reservationTo).format('YYYY-MM-DD')} 23:59:00`,
                targetDeliveryFrom:
                deliveryDate &&
                  moment.utc(deliveryDate).isValid() &&
                  `${moment.utc(deliveryDate).format('YYYY-MM-DD')} 00:00:00`,
                targetDeliveryTo:
                returnDelivery &&
                  moment.utc(returnDelivery).isValid() &&
                  `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 23:59:00`,
                targetReturnDeliveryFrom:
                reservationTo &&
                  moment.utc(reservationTo).isValid() &&
                  `${moment.utc(reservationTo).format('YYYY-MM-DD')} 00:00:00`,
                targetReturnDeliveryTo:
                returnDelivery &&
                  moment.utc(returnDelivery).isValid() &&
                  `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 23:59:00`,
                targetReconditioningFrom:
                returnDelivery &&
                  moment.utc(returnDelivery).isValid() &&
                  `${moment.utc(returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
                targetReconditioningTo:
                checkedDate &&
                  moment.utc(checkedDate).isValid() &&
                  `${moment.utc(checkedDate).format('YYYY-MM-DD')} 23:59:00`,
                end: `${moment.utc(checkedDate).format('YYYY-MM-DD')} 23:59:00`,
                isTwentyFourHourReservation: twentyFourHourReservation,
                borrower: `${borrowerFname} ${borrowerLname}`,
                customerName:
                  (customerFname || customerLname) &&
                  `${customerFname} ${customerLname}`,
                stockController: `${stockControllerFname} ${stockControllerLname}`,
                comment: comment || '',
                organization,
                city,
                state,
                transactionStatusId,
                businessUnitDesignation,
                countryShortName,
                transactionNumber
              };
              transactionObject = {
                ...transactionObject,
                ...calculateCalendarDates(transactionObject),
              };

              alreadyBooked.push(transactionObject);
            }
            else {
              if (targetDeliveryFrom && targetReconditioningTo) {
                if (nextC2cId) {
                  newTargetReturnDeliveryTo = targetReservationTo
                  newTargetReturnDeliveryFrom = targetReservationTo
                  newTargetReconditioningTo = targetReservationTo;
                  newTargetReconditioningFrom = targetReservationTo;
                  newEnd = targetReservationTo
                }

                let transactionObject = {
                  transactionsId,
                  nextC2cId,
                  prevC2cId,
                  kit,
                  title: '',
                  id: index + 1,
                  isBooked: true,
                  ...calculateNumberOfDates({ transaction: transaction, kit }),
                  resourceId: parseInt(kitInformationId),
                  start: `${moment.utc(targetDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReservationFrom:
                    targetReservationTo &&
                    moment.utc(targetReservationFrom).isValid() &&
                    `${moment.utc(targetReservationFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReservationTo:
                    targetReservationTo &&
                    moment.utc(targetReservationTo).isValid() &&
                    `${moment.utc(targetReservationTo).format('YYYY-MM-DD')} 23:59:00`,
                  targetDeliveryFrom:
                  targetDeliveryFrom &&
                    moment.utc(targetDeliveryFrom).isValid() &&
                    `${moment.utc(targetDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetDeliveryTo:
                  targetReturnDeliveryTo &&
                    moment.utc(targetReturnDeliveryTo).isValid() &&
                    `${moment.utc(targetReturnDeliveryTo).format('YYYY-MM-DD')} 23:59:00`,
                  targetReturnDeliveryFrom:
                    newTargetReturnDeliveryFrom &&
                    moment.utc(newTargetReturnDeliveryFrom).isValid() &&
                    `${moment.utc(newTargetReturnDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReturnDeliveryTo:
                    newTargetReturnDeliveryTo &&
                    moment.utc(newTargetReturnDeliveryTo).isValid() &&
                    `${moment.utc(newTargetReturnDeliveryTo).format('YYYY-MM-DD')} 23:59:00`,
                  targetReconditioningFrom:
                  newTargetReconditioningFrom &&
                    moment.utc(newTargetReconditioningFrom).isValid() &&
                    `${moment.utc(newTargetReconditioningFrom).format('YYYY-MM-DD')} 00:00:00`,
                  targetReconditioningTo:
                  newTargetReconditioningTo &&
                    moment.utc(newTargetReconditioningTo).isValid() &&
                    `${moment.utc(newTargetReconditioningTo).format('YYYY-MM-DD')} 23:59:00`,
                  end: `${moment.utc(newEnd).format('YYYY-MM-DD')} 23:59:00`,
                  isTwentyFourHourReservation: twentyFourHourReservation,
                  borrower: `${borrowerFname} ${borrowerLname}`,
                  customerName:
                    (customerFname || customerLname) &&
                    `${customerFname} ${customerLname}`,
                  stockController: `${stockControllerFname} ${stockControllerLname}`,
                  comment: comment || '',
                  organization,
                  city,
                  state,
                  transactionStatusId,
                  businessUnitDesignation,
                  countryShortName,
                  transactionNumber
                };
                transactionObject = {
                  ...transactionObject,
                  ...calculateCalendarDates(transactionObject),
                };
                alreadyBooked.push(transactionObject);
              }
            }
          }
          let actualReturnDeliveryDate = reservationTo;
          let targetReturnDeliveryFromDate = targetReturnDeliveryFrom;

          let actualDate = checkedDate;
          let targetDate = targetReconditioningTo;
          let targetCheckedDateFormat = targetReconditioningTo && moment.utc(targetReconditioningTo).isValid() && moment.utc(targetReconditioningTo).format('YYYY-MM-DD');
          let checkedDateFormat = checkedDate && moment.utc(checkedDate).isValid() && moment.utc(checkedDate).format('YYYY-MM-DD');
          if(nextC2cId || prevC2cId){
            targetCheckedDateFormat = targetReturnDeliveryTo && moment.utc(targetReturnDeliveryTo).isValid() && moment.utc(targetReturnDeliveryTo).format('YYYY-MM-DD');
            checkedDateFormat = returnDelivery && moment.utc(returnDelivery).isValid() && moment.utc(returnDelivery).format('YYYY-MM-DD');
            actualDate = returnDelivery;
            targetDate = targetReturnDeliveryTo;
          }


          if((prevC2cId === null && nextC2cId !== null) || (prevC2cId !== null && nextC2cId !== null)){
            targetCheckedDateFormat = targetReturnDeliveryFromDate && moment.utc(targetReturnDeliveryFromDate).isValid() && moment.utc(targetReturnDeliveryFromDate).format('YYYY-MM-DD');
            checkedDateFormat = actualReturnDeliveryDate && moment.utc(actualReturnDeliveryDate).isValid() && moment.utc(actualReturnDeliveryDate).format('YYYY-MM-DD');
          }

          const isOverdueCheckedDate = targetCheckedDateFormat !== checkedDateFormat;
          if (isOverdueCheckedDate) {
            let overdueCheckedDate
            if(nextC2cId || prevC2cId){
              if (nextC2cId === null && prevC2cId !== null){
               actualDate = checkedDate
               targetDate = targetReconditioningTo
              }else{
                actualDate = reservationTo
                targetDate = targetReturnDeliveryFromDate
              }
              overdueCheckedDate = getOverdueCheckedDate(transactionsId, kitInformationId, kit, targetDate, actualDate, index);
            }else{

              overdueCheckedDate = getOverdueCheckedDate(transactionsId, kitInformationId, kit, targetReconditioningTo, checkedDate, index);
            }
            alreadyBooked.push({
              ...overdueCheckedDate,
              borrower: `${borrowerFname} ${borrowerLname}`,
              customerName:
                (customerFname || customerLname) &&
                `${customerFname} ${customerLname}`,
              stockController: `${stockControllerFname} ${stockControllerLname}`,
              comment: comment || '',
              organization,
              city,
              state,
              transactionStatusId,
              businessUnitDesignation,
              countryShortName,
              transactionNumber
            });
          }
        });
      }
    }
  });

  return sortBy(alreadyBooked, (item) => new Date(item.start));
}

export const getOverdueCheckedDate = (transactionsId, kitInformationId, kit, targetReconditioningTo, checkedDate, index) => {
  let overdueCheckedDate = {
    transactionsId,
    kit,
    title: '',
    id: index + 1,
    resourceId: parseInt(kitInformationId),
    isCheckedDate: true,
    showPopover: true,
  };
  if (checkedDate) {
    overdueCheckedDate = {
      ...overdueCheckedDate,
      start: `${moment.utc(targetReconditioningTo).format('YYYY-MM-DD')} 00:00:00`,
      end: `${moment.utc(checkedDate).format('YYYY-MM-DD')} 23:59:00`,
      overdueCheckedDays:  moment(`${moment.utc(checkedDate).format('YYYY-MM-DD')} 12:00`).diff(
        moment(`${moment.utc(targetReconditioningTo).format('YYYY-MM-DD')} 12:00`),
        'days',
      ) + 1,
    };
  } else {
    overdueCheckedDate = {
      ...overdueCheckedDate,
      start: `${moment.utc(targetReconditioningTo).format('YYYY-MM-DD')} 00:00:00`,
      end: `${moment(new Date()).format('YYYY-MM-DD')} 23:59:00`,
      overdueCheckedDays:  moment(`${moment(new Date()).format('YYYY-MM-DD')} 12:00`).diff(
        moment(`${moment.utc(targetReconditioningTo).format('YYYY-MM-DD')} 12:00`),
        'days',
      ) + 1,
    };
  }

  return overdueCheckedDate;
};

const calculateCalendarDates = transaction => {
  let {
    targetDeliveryFrom,
    targetReservationFrom,
    targetReservationTo,
    targetReturnDeliveryTo,
    targetReconditioningFrom,
    targetReconditioningTo,
    kit,
  } = transaction;
  let deliveryDays = kit.deliveryDays;
  let returnDeliveryDays = kit.returnDeliveryDays;
  let reconditioningDays = kit.reconditioningDeliveryDays;
  if (
    targetDeliveryFrom &&
    targetReservationFrom &&
    moment.utc(targetReservationFrom).format('YYYY-MM-DD') !== moment.utc(targetDeliveryFrom).format('YYYY-MM-DD')
  ) {
    deliveryDays = moment.utc(targetReservationFrom).diff(moment.utc(targetDeliveryFrom), 'days');
  } else {
    deliveryDays = 0;
  }
  if (
    targetReservationTo &&
    targetReconditioningTo &&
    moment.utc(targetReservationTo).format('YYYY-MM-DD') !== moment.utc(targetReconditioningFrom).format('YYYY-MM-DD')
  ) {
    returnDeliveryDays = moment.utc(targetReconditioningTo).diff(moment.utc(targetReservationTo), 'days');
  } else {
    returnDeliveryDays = 0;
  }
  if (
    targetReturnDeliveryTo &&
    targetReconditioningTo &&
    moment.utc(targetReservationTo).format('YYYY-MM-DD') !== moment.utc(targetReconditioningFrom).format('YYYY-MM-DD')
  ) {
    reconditioningDays = moment.utc(targetReconditioningTo).diff(moment.utc(targetReturnDeliveryTo), 'days');
  } else {
    reconditioningDays = 0;
  }
  return { deliveryDays, returnDeliveryDays, reconditioningDays };
};

const calculateNumberOfDates = ({ transaction, kit }) => {
  let deliveryDays = kit.deliveryDays;
  let returnDeliveryDays = kit.returnDeliveryDays;
  let reconditioningDeliveryDays = kit.reconditioningDeliveryDays;
  if (
    transaction.targetReservationFrom &&
    moment(transaction.targetReservationFrom).isValid() &&
    transaction.targetDeliveryFrom &&
    moment(transaction.targetDeliveryFrom).isValid()
  ) {
    deliveryDays = moment(`${moment.utc(transaction.targetReservationFrom).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment.utc(transaction.targetDeliveryFrom).format('YYYY-MM-DD')} 12:00`),
      'days',
    );
  }
  if (
    transaction.targetReconditioningTo &&
    moment(transaction.targetReconditioningTo).isValid() &&
    transaction.targetReservationTo &&
    moment(transaction.targetReservationTo).isValid()
  ) {
    returnDeliveryDays = moment(`${moment.utc(transaction.targetReconditioningTo).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment.utc(transaction.targetReservationTo).format('YYYY-MM-DD')} 12:00`),
      'days',
    );
  }
  if (
    transaction.targetReconditioningTo &&
    moment(transaction.targetReconditioningTo).isValid() &&
    transaction.targetReconditioningFrom &&
    moment(transaction.targetReconditioningFrom).isValid()
  ) {
    reconditioningDeliveryDays =
      moment(`${moment.utc(transaction.targetReconditioningTo).format('YYYY-MM-DD')} 12:00`).diff(
        moment(`${moment.utc(transaction.targetReconditioningFrom).format('YYYY-MM-DD')} 12:00`),
        'days',
      ) + 1;
  }
  if (
    moment(`${moment.utc(transaction.targetReconditioningTo).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment.utc(transaction.targetDeliveryFrom).format('YYYY-MM-DD')} 12:00`),
      'days',
    ) <= 1
  ) {
    return {};
  }
  return { deliveryDays, returnDeliveryDays, reconditioningDeliveryDays };
};

export const fetchStocksForUser = () => dispatch => {
  const dataToSend = {
    page: 0,
    size: -1,
    filter: [],
    sort: [],
    graphql:
      'id,name,shortName,activeStocksAccessible[locationName,locationShortName,businessUnit[name],country[shortName]]',
  };

  fetchStocksForCurrentUserService(dataToSend)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_STOCKS_FOR_USERS,
          payload: res.data,
        });
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_STOCKS_FOR_USERS,
        payload: [],
      });
    });
};

export const checkAvailability = items => dispatch => {
  return new Promise((resolve, reject) => {
    const kitIds = Array.isArray(items) ? items.map(item => item.resourceId) : [items.resourceId];
    const kit = Array.isArray(items) ? items[0] : items;
    try {
      const dataToSend = {
        kitIds,
        from: moment(kit.start).format('YYYY-MM-DD'),
        to: moment(kit.end).format('YYYY-MM-DD'),
        shipToCountryId: kit && kit.kit && kit.kit.specialDeliveryTime? kit.kit.specialDeliveryTime.countryId: kit.hasOwnProperty('country') ? kit.country : null,
        shipToStateId:0
      };

      dispatch(changeLoader(true));

      checkBookingDatesAvailabilityService(dataToSend)
        .then(res => {
          if (res.data.requestedPeriodValid) {
            resolve({ success: true });
          } else {
            reject({ success: false, isUIError: false, data: res.data });
          }
          dispatch(changeLoader(false));
        })
        .catch(err => {
          dispatch(changeLoader(false));
          reject({ success: false, isUIError: true, error: err });
        });
    } catch (e) {
      reject({ success: false, isUIError: true, error: null });
    }
  });
};

export const fetchCalendarFilters = isLabsLocation => async (dispatch, getState) => {
  let { countries, categories } = getState().masterDataReducer;
  const location = isLabsLocation ? LAB_LOCATION_CALENDAR : REGULAR_CALENDAR;
  try {
    const { data } = await fetchMasterDataService(`${URLS.calendarFilters}?location=${location}`);
    if (data) {
      const filters = {
        loanType: 1,
        loanTypeFullValue: { name: 'Demo loan', id: 1 },
        category: data.systemMainComponentIds,
        categoryFullValue: getCategoryFullValueByIds(data.systemMainComponentIds, categories),
        stock: data.stockIds,
        stockFullValue: getStockFullValueByIds(data.stockIds, countries),
      };
      dispatch({
        type: isLabsLocation ? UPDATE_LABS_LOCATION_FILTERS : UPDATE_FILTERS,
        payload: filters,
      });
      return filters;
    }
  } catch (error) {
    return {
      loanType: 1,
      loanTypeFullValue: { name: 'Demo loan', id: 1 },
      category: null,
      categoryFullValue: null,
      stock: null,
      stockFullValue: null,
    }
  }
};

export const getStockInformation = id => async (dispatch, getState) => {
  try {
    const res = await fetchMasterDataService(URLS.getStockInfo(id));
    return ({success: true, res})
  } catch (error) {
    return ({success: false})
  }

}

export const sendKitSignalizeDemand = dataToSend => async (dispatch, getState) => {
  try {
     await fetchMasterDataServicePost(URLS.signalizeDemand, dataToSend);
     scrollToTop(500);
     dispatch(toggleActionMessage(true, 'success', 'kitDemandSucessMessage'));
    return ({success: true})
  } catch (error) {
    dispatch(toggleActionMessage(true, 'success', 'somethingWentWrongMessage'));
    return ({success: false})
  }

}

export const fetchSystemClass = buName => async(dispatch, getState) => {
  try {
    const res = await fetchMasterDataService(URLS.systemclassesBuName(buName));
    return ({success: true, res})
  } catch (error) {
    return ({success: false})
  }
}

export const labsLocationAdvanceBookingError = (kitIds, from) => async(dispatch, getState) => {
  try {
    dispatch(changeLoader(true));
    const dataToSend = {kitIds, from}
    const res = await fetchMasterDataServicePost(URLS.advanceBooking, dataToSend);
    dispatch(changeLoader(false));
    return ({success: true})
  } catch (error) {
    dispatch(changeLoader(false));
    return ({success: false, error})
  }
}

export const fetchClandarBlocking = () => async(dispatch, getState) => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePost(URLS.calendarBlocking);
    dispatch(changeLoader(false));
    return ({success: true, res})
  } catch (error) {
    dispatch(changeLoader(false));
    return ({success: false});
  }
}

export const zmccCreateSlotAvailaibility = (dataToSend) => async(dispatch, getState) => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePost(URLS.zmccCreateSlotAvailaibility, dataToSend);
    dispatch(changeLoader(false));
    return ({success: true, res})
  } catch (error) {
    dispatch(changeLoader(false));
    return ({success: false, error})
  }
}

export const zmccRemoveSlotAvailaibility = (idstoDelete) => async(dispatch, getState) => {
  try {
    dispatch(changeLoader(true));
    const res = await deleteMasterDataService(URLS.zmccRemoveSlotAvailaibility,idstoDelete);
    dispatch(changeLoader(false));
    return ({success: true, res})
  } catch (error) {
    dispatch(changeLoader(false));
    return ({success: false, error})
  }
}

export const createZmccBooking = (dataToSend, history) => async (dispatch) =>{
  try {
    dispatch(changeLoader(true));
   const {status} = await fetchMasterDataServicePost(URLS.createZmccBooking, dataToSend)
    dispatch(changeLoader(false));
    if (status === 200) {
      history.push('/my-bookings?type=zmcc');
      return ({success: true})
    }else {
      return ({success: false})
    }

  } catch (error) {
    dispatch(changeLoader(false));
    if (error?.response?.data?.status === 422) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    }else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
    return ({success: false})
  }
}

export const fetchSystemComponentsBySystemClasses = businessUnitIds => async dispatch => {
  try {
    const { data } = await fetchMasterDataServicePost(URLS.systemComponentsByIds, {ids: businessUnitIds});

    dispatch({
      type: SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES,
      payload: data.map(item => ({ ...item, systemMainComponentName: item.systemMainComponentText })),
    });
  } catch (err) {
    dispatch({
      type: SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES,
      payload: [],
    });
  }
};

export const fetchIqsLocations = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.commentLocations);
    dispatch({
      type: FETCH_IQS_COMMENT_LOCATIONS,
      payload: data,
    });
    return ({success: true})
  }catch (error) {
    dispatch({
      type: FETCH_IQS_COMMENT_LOCATIONS,
      payload: [],
    });
    return ({success: false})
  }
}

export const createOrEditIqsComment = ( dataToSend ) => async (dispatch) =>{
  try {
    const calendarState = store.getState().calendar;
    dispatch(changeLoader(true));
    const {status, data} = await fetchMasterDataServicePost(URLS.createLocationComment, dataToSend);   
    if (status === 200) {
      const newComment = {
        ...data,
        id: data.commentId,
        start: new Date(data.eventStart),
        end: new Date(data.eventEnd),
        resourceId: `${data.stockId}__comments`,
        commentOnly: true,
        publicHoliday: data.publicHoliday,
        stockId: data.stockId,
        resizable: false,
        movable: false
      };

      const commentIndex = calendarState.iqsAddedCommentList.findIndex(comment => comment.id === data.commentId);

      let commentList;
      if (commentIndex !== -1) {
        // Update existing comment
        commentList = [
          ...calendarState.iqsAddedCommentList.slice(0, commentIndex),
          newComment,
          ...calendarState.iqsAddedCommentList.slice(commentIndex + 1),
        ];
      } else {
        // Add new comment
        commentList = [...calendarState.iqsAddedCommentList, newComment];
      }

      dispatch({
        type: IQS_ADDED_COMMENT_LIST,
        payload: commentList,
      });
    }
    scrollToTop(500);
    dispatch(changeLoader(false));
    if (dataToSend.commentId !== null) {
      dispatch(toggleActionMessage(true, 'success', 'updateLocationCommentMessage'));
    }else{
      dispatch(toggleActionMessage(true, 'success', 'locationCommentSuccessMessage'));
    }
      return ({success: true})
  } catch (error) {
    dispatch(changeLoader(false));
    return ({success: false , error})
  }
};

export const fetchIqsCommentsList = (locationIds, dispalyStartDate, dispalyEndDate) => async (dispatch) =>{ 
  try {
    const url = `${URLS.fetchLocationComments}?fromDate=${dispalyStartDate}&toDate=${dispalyEndDate}`;
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(url,locationIds);
    let updatedData = []
    if (data && data.length > 0) {
      updatedData = data.map(item => {
        return {
          ...item,
          id: item.commentId,
          start: new Date(item.eventStart),
          end: new Date(item.eventEnd),
          resourceId: `${item.stockId}__comments`,
          commentOnly: true,
          resizable: false,
          movable: false,
          bgColor: `#dfdfe375`,
        }
      })  
    }
    dispatch({
      type: IQS_ADDED_COMMENT_LIST,
      payload: updatedData,
    });
    dispatch(changeLoader(false));
    return ({success: true, data})
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: IQS_ADDED_COMMENT_LIST,
      payload: [],
    });
    return ({success: false})
  }
}