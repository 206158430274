import {
    fetchMasterDataServicePost,
    fetchMasterDataService,
    fetchMasterDataServicePut,
  } from 'library/api/master-data.service';
import {
  FETCH_CONNECTED_TRANSACTIONS_LIST,
  FETCH_RMS_ZMCC_USER,
  FETCH_ZMCC_OPERATOR_FOR_STOCK,
  FETCH_ZMCC_PRIORITES,
  FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK,
  FETCH_ZMCC_TRANSACTION_DETAILS_STATUSES,
  GET_WHOLE_ZMCC_TRANSACTION_DETAILS,
  SET_SEM_FORM_VALUES,
  FETCH_ZMCC_OPERATOR_SCHEDULES,
  FETCH_SAMPLE_AT
} from './ZmccTransactionDetails.constants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { createFormData, handleValuesToSend, updateSEMForm, uploadKitAttachment } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { getChangeHistory } from 'modules/Transactions/TransactionDetails/TransactionDetailsActions';
import { regex } from 'library/common/commonConstants/RegexConstants';
import { generalInformationModel, projectDetailsModel, schedulingInformationModel } from 'modules/ZmccRequest/SEMBooking/Components/Assignment/Tabs/GeneralInformation/GeneralInformation.constants';
import { customerBackgroundModel, customerDetilsModel, primaryPointOfContactModel } from 'modules/ZmccRequest/SEMBooking/Components/Assignment/Tabs/CustomerInformation/CustomerInformation.constants';
import { mainApplicationChecklist } from 'modules/ZmccRequest/SEMBooking/Components/Assignment/Tabs/ApplicationSampleDetails/ApplicationSampleDetails.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { xrmSamplesSpecModel } from 'modules/ZmccRequest/SEMBooking/Components/Assignment/Tabs/XRMApplicationSamplesDetails/XRMApplicationSamplesDetails.constants';
import { xbMainApplicationChecklist } from 'modules/ZmccRequest/SEMBooking/Components/Assignment/Tabs/XBApplicationSamplesDetails/XBApplicationSampleDetails.constants';
import { isObject } from 'lodash';
import { validateNameEmailAndFunctionInAttendees } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';

export const getWholeSemTransaction = transactionId => async dispatch => {
  console.log(transactionId, "transactionId");
  const transId = transactionId.split('?');
  dispatch(changeLoader(true));
  try {
    const res = transactionId && (await fetchMasterDataService(URLS.getWholeZmccTransactionDetails(transId[0])));
    dispatch(changeLoader(false));
    dispatch(fetchMasterData(`${URLS.transactionStatuses}?type=ZMCC`, FETCH_ZMCC_TRANSACTION_DETAILS_STATUSES));
    dispatch({ type: GET_WHOLE_ZMCC_TRANSACTION_DETAILS, payload: res.data });
    const formData = createFormData(res.data);
    dispatch({ type: SET_SEM_FORM_VALUES, payload: { ...formData, zmccTransactionDetails: res.data } });
    dispatch(updateSEMForm(formData));
    return { success: true };
  } catch (err) {
    dispatch(changeLoader(false));
    dispatch({ type: GET_WHOLE_ZMCC_TRANSACTION_DETAILS, payload: [] });
    return { success: false };
  }
};

export const fetchZmccUser = () => async dispatch => {
  try {
    const res = await fetchMasterDataService(URLS.rmsZmccUser);
    dispatch({ type: FETCH_RMS_ZMCC_USER, payload: res.data.body });
    return { success: true };
  } catch (err) {
    dispatch({ type: FETCH_RMS_ZMCC_USER, payload: [] });
    return { success: false };
  }
};

export const fetchOperatorsForTransactions = (zmccStockId) => async dispatch => {
  try{
    const res = await fetchMasterDataService(URLS.rmsZmccOperators(zmccStockId));
    dispatch({type: FETCH_ZMCC_OPERATOR_FOR_STOCK, payload: res.data})
    return {success: true}
  }catch(err){
    dispatch({type: FETCH_ZMCC_OPERATOR_FOR_STOCK, payload: []})
    return {success: false}
  }
}

export const fetchZmccUsersForSubscriber = (zmccStockId) => async dispatch => {
  try{
    const res = await fetchMasterDataService(URLS.rmsZmccSubscribers(zmccStockId));
    dispatch({type: FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK, payload: res.data})
    return {success: true}
  }catch(err){
    dispatch({type: FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK, payload: []})
    return {success: false}
  }
}

export const fetchZmccPriorites = () => async dispatch => {
  try {
    const res = await fetchMasterDataService(URLS.zmccPriorites);
    dispatch({ type: FETCH_ZMCC_PRIORITES, payload: res.data });
    return { success: true };
  } catch (err) {
    dispatch({ type: FETCH_ZMCC_PRIORITES, payload: [] });
    return { success: false };
  }
};

export const updateZmccTransaction = formType => async (dispatch, getState) => {
  let { zmccTransactionDetailsReducer,  authReducer } = getState();
  let formValues = {
    generalInformationForm: zmccTransactionDetailsReducer.generalInformationForm,
    schedulingInformationForm: zmccTransactionDetailsReducer.schedulingInformationForm,
    projectDetailsForm: zmccTransactionDetailsReducer.projectDetailsForm,
    customerDetilsForm: zmccTransactionDetailsReducer.customerDetilsForm,
    primaryPointOfContactForm: zmccTransactionDetailsReducer.primaryPointOfContactForm,
    customerBackgroundForm: zmccTransactionDetailsReducer.customerBackgroundForm,
    mainApplicationChecklistForm: zmccTransactionDetailsReducer.mainApplicationChecklistForm,
    sampleDeatilsFrom: zmccTransactionDetailsReducer.sampleDeatilsFrom,
    demoCenterInformationForm: zmccTransactionDetailsReducer.demoCenterInformationForm,
    additionalInformationForm: zmccTransactionDetailsReducer.additionalInformationForm,
    logisticsAndCoordinationForm: zmccTransactionDetailsReducer.logisticsAndCoordinationForm,
    todosListForm: zmccTransactionDetailsReducer.todosListForm,
    transactionsId: zmccTransactionDetailsReducer.transactionsId,
    kitAttachmentsToDelete: zmccTransactionDetailsReducer.kitAttachmentsToDelete,
    xbMainApplicationChecklistForm: zmccTransactionDetailsReducer.xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom: zmccTransactionDetailsReducer.xbSampleDeatilsFrom,
    xrmDemoDetailsForm: zmccTransactionDetailsReducer.xrmDemoDetailsForm,
    xrmApplicationInformationForm: zmccTransactionDetailsReducer.xrmApplicationInformationForm,
    xrmSamplesDetailsForm: zmccTransactionDetailsReducer.xrmSamplesDetailsForm,
    xrmSampleDescriptionForm: zmccTransactionDetailsReducer.xrmSampleDescriptionForm,
    xrmSampleSpecsForm: zmccTransactionDetailsReducer.xrmSampleSpecsForm,
    lmApplicationAndSamplesForm: zmccTransactionDetailsReducer.lmApplicationAndSamplesForm,
    deleteTodoIds: zmccTransactionDetailsReducer.deleteTodoIds,
    onAddMorreClickedInTodoTab: zmccTransactionDetailsReducer.onAddMorreClickedInTodoTab,
    onAddMoreClickedINDemoCentreTab: zmccTransactionDetailsReducer.onAddMoreClickedINDemoCentreTab,
    isCustomerAttendeeAdded: zmccTransactionDetailsReducer?.zmccTransactionDetails?.isCustomerAttendeeAdded,
    isPOCtAttendeeAdded: zmccTransactionDetailsReducer?.zmccTransactionDetails?.isPOCtAttendeeAdded
  };

  const validateArray = [
    { formName: 'generalInformationForm', form: formValues.generalInformationForm, model: generalInformationModel },
    {
      formName: 'schedulingInformationForm',
      form: formValues.schedulingInformationForm,
      model: schedulingInformationModel,
    },
    { formName: 'projectDetailsForm', form: formValues.projectDetailsForm, model: projectDetailsModel },

    { formName: 'customerDetilsForm', form: formValues.customerDetilsForm, model: customerDetilsModel },
    {
      formName: 'primaryPointOfContactForm',
      form: formValues.primaryPointOfContactForm,
      model: primaryPointOfContactModel,
    },
    { formName: 'customerBackgroundForm', form: formValues.customerBackgroundForm, model: customerBackgroundModel },
  ];

  if (formType.toLowerCase() === 'sem') {
    validateArray.push({
      formName: 'mainApplicationChecklistForm',
      form: formValues.mainApplicationChecklistForm,
      model: mainApplicationChecklist,
    });
  }
  if (formType.toLowerCase() === 'xb') {
    validateArray.push({
      formName: 'xbMainApplicationChecklistForm',
      form: formValues.xbMainApplicationChecklistForm,
      model: xbMainApplicationChecklist,
    });
  }

  if (formType.toLowerCase() === 'xrm') {
    validateArray.push({
      formName: 'xrmSampleSpecsForm',
      form: formValues.xrmSampleSpecsForm,
      model: xrmSamplesSpecModel,
    });
  }

  validateArray.forEach(({ formName, form, model }) => {
    const newForm = validateForm({ form, model });
    formValues[formName] = newForm;
  });

  if (!formValues.generalInformationForm.isFormValid) {
    dispatch(updateSEMForm({ generalInformationForm: formValues.generalInformationForm }));
  }

  if (!formValues.schedulingInformationForm.isFormValid) {
    dispatch(updateSEMForm({ schedulingInformationForm: formValues.schedulingInformationForm }));
  }

  if (!formValues.projectDetailsForm.isFormValid) {
    dispatch(updateSEMForm({ projectDetailsForm: formValues.projectDetailsForm }));
  }

  if (!formValues.customerDetilsForm.isFormValid) {
    dispatch(updateSEMForm({ customerDetilsForm: formValues.customerDetilsForm }));
  }

  if (!formValues.primaryPointOfContactForm.isFormValid) {
    dispatch(updateSEMForm({ primaryPointOfContactForm: formValues.primaryPointOfContactForm }));
  }

  if (!formValues.customerBackgroundForm.isFormValid) {
    dispatch(updateSEMForm({ customerBackgroundForm: formValues.customerBackgroundForm }));
  }

  if (formType.toLowerCase() === 'sem' && !formValues.mainApplicationChecklistForm.isFormValid) {
    dispatch(updateSEMForm({ mainApplicationChecklistForm: formValues.mainApplicationChecklistForm }));
  }
  if (formType.toLowerCase() === 'xb' && !formValues.xbMainApplicationChecklistForm.isFormValid) {
    dispatch(updateSEMForm({ xbMainApplicationChecklistForm: formValues.xbMainApplicationChecklistForm }));
  }
  if (formType.toLowerCase() === 'xrm' && !formValues.xrmSampleSpecsForm.isFormValid) {
    dispatch(updateSEMForm({ xrmSampleSpecsForm: formValues.xrmSampleSpecsForm }));
  }

  if (formType.toLowerCase() === 'sem' && !formValues.sampleDeatilsFrom.isFormValid) {
    if (!formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
      console.log(formValues.sampleDeatilsFrom.formData.totalNumberOfSamples,"formValues.sampleDeatilsFrom.formData.totalNumberOfSamples");
      formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
    } else if (formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
      let result = regex.number.test(formValues.sampleDeatilsFrom.formData.totalNumberOfSamples);
      if (!result) {
        formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'invalidNumberError';
        dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
      }
    }

    if (!formValues.sampleDeatilsFrom.formData.statusOfSampleId) {
      console.log(formValues.sampleDeatilsFrom.formData.statusOfSampleId, "formValues.sampleDeatilsFrom.formData.statusOfSampleId");
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
      dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
    }

    if (!formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging) {
      console.log(formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging, "formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging");
      formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
      formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingFullValue = 'null';
      dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
    }

    if (formValues.sampleDeatilsFrom.formData.statusOfSampleSolved && formValues.sampleDeatilsFrom.formData.completedByName === "") {
      formValues.sampleDeatilsFrom.formData.completedByNameError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
    }
  }

  if (formType.toLowerCase() === 'xb' && !formValues.xbSampleDeatilsFrom.isFormValid) {
    if (!formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleId) {
      console.log(formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleId, "formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleId");
      formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdError = 'requiredErrorMessage';
      formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdFullValue = 'null';
      dispatch(updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom }));
    }

    if (!formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImaging) {
      console.log(formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImaging, "formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImaging");
      formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
      formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingFullValue = 'null';
      dispatch(updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom }));
    }

    if (!formValues.xbSampleDeatilsFrom.formData.detailedDescription) {
      console.log(formValues.xbSampleDeatilsFrom.formData.detailedDescription, "formValues.xbSampleDeatilsFrom.formData.detailedDescription");
      formValues.xbSampleDeatilsFrom.formData.detailedDescriptionError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom }));
    }
    if (!formValues.xbSampleDeatilsFrom.formData.sampleSpecificComments) {
      console.log(formValues.xbSampleDeatilsFrom.formData.sampleSpecificComments, "formValues.xbSampleDeatilsFrom.formData.sampleSpecificComments");
      formValues.xbSampleDeatilsFrom.formData.sampleSpecificCommentsError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom }));
    }
    if (formValues.xbSampleDeatilsFrom.formData.statusOfSampleSolvedXb && formValues.xbSampleDeatilsFrom.formData.completedByNameXb === "") {
      formValues.xbSampleDeatilsFrom.formData.completedByNameXbError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom }));
    }
  }

  if (formType.toLowerCase() === 'xrm') {
    if (!formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemo) {
      console.log(formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemo, "formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemo");
      formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemoError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xrmDemoDetailsForm: formValues.xrmDemoDetailsForm }));
    }
    if (!formValues.xrmDemoDetailsForm.formData.customerSystemCompetition) {
      console.log(formValues.xrmDemoDetailsForm.formData.customerSystemCompetition, "formValues.xrmDemoDetailsForm.formData.customerSystemCompetition");
      formValues.xrmDemoDetailsForm.formData.customerSystemCompetitionError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xrmDemoDetailsForm: formValues.xrmDemoDetailsForm }));
    }
    if (!formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer) {
      console.log(formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer, "formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer");
      formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomerError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xrmDemoDetailsForm: formValues.xrmDemoDetailsForm }));
    }
  }

  if (formType.toLowerCase() === 'xrm') {
    if (!formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocus) {
      console.log(formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocus, "formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocus");
      formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocusError =
        'requiredErrorMessage';
      dispatch(updateSEMForm({ xrmApplicationInformationForm: formValues.xrmApplicationInformationForm }));
    }
  }

  if(formType.toLowerCase() !== 'xb'){
    if (!formValues.sampleDeatilsFrom.formData.statusOfSampleId) {
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
      updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
    }else{
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = null;
      updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
    }

    if (formValues.sampleDeatilsFrom.formData.statusOfSampleSolved && !formValues.sampleDeatilsFrom.formData.completedByName) {
      formValues.sampleDeatilsFrom.formData.completedByNameError = 'requiredErrorMessage';
      updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
    }else{
      formValues.sampleDeatilsFrom.formData.completedByNameError = null
      updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
    }
  }

  if (formType.toLowerCase() === 'xrm') {
    if (!formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImaging) {
      console.log(formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImaging, "formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImaging");
      formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xrmSamplesDetailsForm: formValues.xrmSamplesDetailsForm }));
    }
  }

  if (formType.toLowerCase() === 'xrm') {
    if (!formValues.xrmSampleDescriptionForm.formData.describeOverallSamples) {
      console.log(formValues.xrmSampleDescriptionForm.formData.describeOverallSamples, "formValues.xrmSampleDescriptionForm.formData.describeOverallSamples");
      formValues.xrmSampleDescriptionForm.formData.describeOverallSamplesError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ xrmSampleDescriptionForm: formValues.xrmSampleDescriptionForm }));
    }
  }

  if (!formValues.demoCenterInformationForm.isFormValid) {
    if (formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList?.length <= 0)
       {
        console.log(formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList?.length <= 0, formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList, "formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList?.length <= 0");
        if (!formValues.demoCenterInformationForm.formData.hasOwnProperty('hardOrSoftwareAccessories')) {
          console.log("came in if of hardOrSoftwareAccessories");
          formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError = 'requiredErrorMessage';
          updateSEMForm({ demoCenterInformationForm: formValues.demoCenterInformationForm });
        }else if (formValues.demoCenterInformationForm.formData.hasOwnProperty('hardOrSoftwareAccessories') && (formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories === "" || formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories === null) ) {
          console.log("came in else if of hardOrSoftwareAccessories");
          formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError = 'requiredErrorMessage';
          updateSEMForm({ demoCenterInformationForm: formValues.demoCenterInformationForm });
        }
      }
  }

  if (formType.toLowerCase() === 'lm' && !formValues.lmApplicationAndSamplesForm.isFormValid) {
    if (!formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomer) {
      console.log(formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomer, "formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomer");
      formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomerError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm }));
    }
    if (!formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemo) {
      console.log(formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemo, "formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemo");
      formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemoError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm }));
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamples) {
      console.log(formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamples, "formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamples");
      formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamplesError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm }));
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.justificationPoints) {
      console.log(formValues.lmApplicationAndSamplesForm.formData.justificationPoints, "formValues.lmApplicationAndSamplesForm.formData.justificationPoints");
      formValues.lmApplicationAndSamplesForm.formData.justificationPointsError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm }));
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSale) {
      console.log(formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSal, "formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSal");
      formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSaleError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm }));
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer) {
      console.log(formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer, "formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer");
      formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomerError = 'requiredErrorMessage';
      dispatch(updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm }));
    }
  }

  if (
    formType.toLowerCase() === 'sem' &&
    (!formValues.generalInformationForm.isFormValid ||
      !formValues.schedulingInformationForm.isFormValid ||
      !formValues.projectDetailsForm.isFormValid ||
      !formValues.customerDetilsForm.isFormValid ||
      !formValues.primaryPointOfContactForm.isFormValid ||
      !formValues.customerBackgroundForm.isFormValid ||
      !formValues.mainApplicationChecklistForm.isFormValid ||
      formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError == 'requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError == 'invalidNumberError' ||
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError == 'requiredErrorMessage' ||
      formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.completedByNameError == 'requiredErrorMessage'
    )
  ) {
    console.log("Error in sem form");
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
    scrollToTop(500);
    return { success: false };
  }

  if (
    formType.toLowerCase() === 'xb' &&
    (!formValues.generalInformationForm.isFormValid ||
      !formValues.schedulingInformationForm.isFormValid ||
      !formValues.projectDetailsForm.isFormValid ||
      !formValues.customerDetilsForm.isFormValid ||
      !formValues.primaryPointOfContactForm.isFormValid ||
      !formValues.customerBackgroundForm.isFormValid ||
      !formValues.xbMainApplicationChecklistForm.isFormValid ||
      formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdError == 'requiredErrorMessage' ||
      formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingError == 'requiredErrorMessage' ||
      formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage' ||
      formValues.xbSampleDeatilsFrom.formData.detailedDescriptionError == 'requiredErrorMessage' ||
      formValues.xbSampleDeatilsFrom.formData.sampleSpecificCommentsError == 'requiredErrorMessage'|| 
      formValues.xbSampleDeatilsFrom.formData.completedByNameXbError == 'requiredErrorMessage'
    )
  ) {
    console.log("Error in xb form");
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
    scrollToTop(500);
    return { success: false };
  }

  if (
    formType.toLowerCase() === 'xrm' &&
    (!formValues.generalInformationForm.isFormValid ||
      !formValues.schedulingInformationForm.isFormValid ||
      !formValues.projectDetailsForm.isFormValid ||
      !formValues.customerDetilsForm.isFormValid ||
      !formValues.primaryPointOfContactForm.isFormValid ||
      !formValues.customerBackgroundForm.isFormValid ||
      !formValues.xrmSampleSpecsForm.isFormValid ||
      formValues.xrmDemoDetailsForm.formData.customerSystemCompetitionError === 'requiredErrorMessage' ||
      formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemoError === 'requiredErrorMessage' ||
      formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImagingError === 'requiredErrorMessage' ||
      formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomerError === 'requiredErrorMessage' ||
      formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocusError ===
        'requiredErrorMessage' ||
      formValues.xrmSampleDescriptionForm.formData.describeOverallSamples === 'requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.completedByNameError =='requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage'
    )
  ) {
    console.log("Error in xrm form");
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
    scrollToTop(500);
    return { success: false };
  }
  if (
    formType.toLowerCase() === 'lm' &&
    (!formValues.generalInformationForm.isFormValid ||
      !formValues.schedulingInformationForm.isFormValid ||
      !formValues.projectDetailsForm.isFormValid ||
      !formValues.customerDetilsForm.isFormValid ||
      !formValues.primaryPointOfContactForm.isFormValid ||
      !formValues.customerBackgroundForm.isFormValid ||
      formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage' ||
      formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomerError ==
        'requiredErrorMessage' ||
      formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemoError == 'requiredErrorMessage' ||
      formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamplesError == 'requiredErrorMessage' ||
      formValues.lmApplicationAndSamplesForm.formData.justificationPointsError == 'requiredErrorMessage' ||
      formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSaleError == 'requiredErrorMessage' ||
      formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomerError == 'requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.completedByNameError =='requiredErrorMessage' ||
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage')
  ) {
    console.log("Error in lm form");
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
    scrollToTop(500);
    return { success: false };
  }

  let  data = formValues.logisticsAndCoordinationForm.formData
  if (data.accomodationNeeded === true && (data.accomodationNeededData === '' || data.accomodationNeededData === null || data.accomodationNeededData === undefined)) {
    formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError = 'requiredErrorMessage';
    dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
      scrollToTop(500);
      return { success: false };
  }
  if(data.flightNeeded === true && (data.flightNeededData === "" || data.flightNeededData === null || data.flightNeededData === undefined)){
    formValues.logisticsAndCoordinationForm.formData.flightNeededDataError = 'requiredErrorMessage';
    dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
      scrollToTop(500);
      return { success: false };
  
  }
  if (data.visitMuseumOfOptics === true &&  (data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null || data.visitMuseumOfOpticsData === undefined)) {
    formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError = 'requiredErrorMessage';
    dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
      scrollToTop(500);
      return { success: false };
  }

  let validations = validateNameEmailAndFunctionInAttendees(formValues.logisticsAndCoordinationForm)
  if(!validations.allowedToSave){
    dispatch(toggleActionMessage(true, 'error', 'pleaseFillRequiredFieldsForAttendee'));
    dispatch(updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}}))
    scrollToTop(500);
    return { success: false };
  }

  if(!validations.allEmpty && !validations.isValid){
    dispatch(toggleActionMessage(true, 'error', 'pleaseFillRequiredFieldsForAttendee'));
    dispatch(updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}}))
    scrollToTop(500);
    return { success: false };
  }

  const { valuesToSend, attachmentsToUpload } = handleValuesToSend(formValues, zmccTransactionDetailsReducer, formType, authReducer);
  let dataToSend = {
    zmccSEMLoanTransactionCreateDTO: {
      ...valuesToSend,
    },
  };
  try {
    dispatch(changeLoader(true));
    await fetchMasterDataServicePost(URLS.updateZmccTransaction(formValues.transactionsId, formType), dataToSend);
    const uploadAttachments = !!attachmentsToUpload.length;
    let successValue;

    if (uploadAttachments) {
      const { success } = await dispatch(uploadKitAttachment(attachmentsToUpload, formValues.transactionsId, true));
      successValue = success;
    }

    if (successValue == false) {
      return;
    }
    dispatch(getWholeSemTransaction(`${formValues.transactionsId}?deatilPage`));
    dispatch(getChangeHistory({ page: 1, transactionsId: formValues.transactionsId }));
    dispatch(changeLoader(false));
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
    return { success: true };
  } catch (error) {
    scrollToTop(500);
    dispatch(changeLoader(false));
    if (error?.response?.status === 422 || error?.response?.status === 403) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    return { success: false };
  }
};

export const fetchConnectedDemoLoans = warehouseId => async dispatch => {
  try {
    const res = await fetchMasterDataService(URLS.zmccConnectedTransaction(warehouseId));
    dispatch({ type: FETCH_CONNECTED_TRANSACTIONS_LIST, payload: res.data });
    return { success: true };
  } catch (err) {
    dispatch({ type: FETCH_CONNECTED_TRANSACTIONS_LIST, payload: [] });
    return { success: false };
  }
};

export const saveAdjustDates = (dataToSend, transactionId) => async dispatch => {
  try {
    await fetchMasterDataServicePut(URLS.saveZmccAdjustDates(transactionId), dataToSend);
    dispatch(getChangeHistory({ page: 1, transactionsId: transactionId }));
    dispatch(getWholeSemTransaction(`${transactionId}?deatilPage`));
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const updateZmccTransactionStatus = (transactionId, statusValue) => async dispatch => {
  let dataTosend = { transactionsId: [transactionId] };
  try {
     await fetchMasterDataServicePut(
      URLS.zmccTransactionStatues(transactionId, statusValue),
      dataTosend,
    );
    dispatch(getChangeHistory({ page: 1, transactionsId: transactionId }));
    dispatch(getWholeSemTransaction(`${transactionId}?deatilPage`));
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'zmccTransactionStatusUpdatedMessage'));
    return { success: true };
  } catch (error) {
    scrollToTop(500);
    if (error?.response?.status === 422 || error?.response?.status === 403) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    return { success: false };
  }
};

export const updateZmccInformationTab = (dataToSend, formType, ...extraArgs) => async dispatch => {
  let showErrorMessageOnPopup = false
  if(extraArgs.length){ 
    extraArgs.forEach((arg) => {
      if(arg?.showErrorMessageOnPopup){
        showErrorMessageOnPopup = true
      }
    })
  }
  try {
    dispatch(changeLoader(true));
     await fetchMasterDataServicePost(
      URLS.updateZmccTransactionDetailsInformationTab(dataToSend.transactionId, formType),
      dataToSend,
    );
    dispatch(getWholeSemTransaction(`${dataToSend.transactionId}?deatilPage`));
    dispatch(getChangeHistory({ page: 1, transactionsId: dataToSend.transactionId }));
    dispatch(changeLoader(false));
    // if(showErrorMessageOnPopup){
    //   return {success: true, message: 'valuesSavedSuccessMessage'}
    // }
    scrollToTop(500);
    
    dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    if((error && error?.response?.status === 422 || error?.response?.status === 403) && showErrorMessageOnPopup){
      return {success: false, message: error?.response?.data?.message}
    } else if(showErrorMessageOnPopup){
      return {success: false, message: 'somethingWentWrongMessage'}
    }
    scrollToTop(500);
    if (error?.response?.status === 422 || error?.response?.status === 403) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    return { success: false };
  }
};

export const fetchOperatorScheduleOfStockOnGivenDate = (dateToSend, stockId) =>  async dispatch => {
  try{
    dispatch(changeLoader(true));
    const response = await fetchMasterDataServicePost(URLS.getOperatorSchedule(dateToSend, stockId), {
      scheduledDate: dateToSend,
      warehouseId:stockId
    });
    dispatch({type: FETCH_ZMCC_OPERATOR_SCHEDULES, payload: response.data})
    return {success: true, data: response.data}
  }catch(err){
    dispatch({type: FETCH_ZMCC_OPERATOR_SCHEDULES, payload: []})
    return {success: false, data: []}
  }finally{
    dispatch(changeLoader(false));
  }
}

export const updateAssignOperatorsForZmcctranscation = (dataToSend, formType, ...extraArgs) => async dispatch => {
  
  let showErrorMessageOnPopup = false
  if(extraArgs.length){ 
    extraArgs.forEach((arg) => {
      if(arg?.showErrorMessageOnPopup){
        showErrorMessageOnPopup = true
      }
    })
  }
  try {
     await fetchMasterDataServicePost(
      URLS.assignOperatorsTpZmcc(dataToSend.transactionId),
      dataToSend,
    );
    dispatch(getWholeSemTransaction(`${dataToSend.transactionId}?deatilPage`));
    dispatch(getChangeHistory({ page: 1, transactionsId: dataToSend.transactionId }));
    dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
    scrollToTop(500);
    return {success: true, message: 'valuesSavedSuccessMessage'}
  } catch (error) {
    if(error &&( error?.response?.status === 422 ||  error?.response?.status === 403)){
      return {success: false, message: error?.response?.data?.message}
    }else if(showErrorMessageOnPopup){
      return {success: false, message: 'somethingWentWrongMessage'}
    }

    if (error?.response?.status === 422 ||  error?.response?.status === 403) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
      scrollToTop(500);
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      scrollToTop(500);
    }
    return { success: false };
  }
};

export const fetchOperatorScheduleDetails = async (dateToSend, transactionId) => {
  try{
    const response = await fetchMasterDataServicePost(URLS.operatorScheduleDetails(transactionId), {
      scheduledDate: dateToSend,
    });
    return {success: true, data: response.data}
  }catch(err){
    return {success: false, data: []}
  }
}

export const fetchSampleAtList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.sampleAt);
    dispatch({ type: FETCH_SAMPLE_AT, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_SAMPLE_AT, payload: [] });
    return { success: false };
  }
};

export const addPreparationPeriodsForRequest = async (dataToSend, transactionId) => {
  try{
    const response = await fetchMasterDataServicePost(URLS.addPreparationPeriods(transactionId), dataToSend);
    if(response.status === 200){
      return {success: true, data: response.data}
    }else{
      return {success: false, data: response.data}
    }
  }catch(err){
    return Promise.reject(err)
  }
}