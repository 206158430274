import Validators from 'library/utilities/Validators';

export const confirmBookingInformationModel = [
  {
    label: 'bookingType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    field: 'bookingType',
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'bookingCategory',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    field: 'bookingCategory',
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'beginStart',
    value: '',
    type: 'dateAndTimePicker',
    placeholder: 'pleaseSelect',
    field: 'bookingFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
  },
  {
    label: 'end',
    value: '',
    type: 'dateAndTimePicker',
    placeholder: 'pleaseSelect',
    field: 'bookingTo',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
  },
];

export const confirmBookingRequestModel = [
  {
    label: 'relatedRequest',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [],
    required: true,
    field: 'relatedRequest',
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'relatedPosition',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [],
    required: true,
    field: 'relatedPosition',
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
];

export const confirmBookingOtherModel = [
  {
    label: 'scheduleInputTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'otherBookingTitle',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'otherBookingDescription',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const addSystemAndLocationModel = [
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'location',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },

  {
    label: 'kits',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    field: 'system',
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'kitInformationId',
    displayValue: [{ key: 'kitName', separator: '' }],
  },
]
