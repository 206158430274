import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import 'react-big-scheduler/lib/css/style.css';
import { get, isEqual, sortBy } from 'lodash';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import cn from 'classnames';
import { fetchFromStorage, saveToStorage} from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import withDragDropContext from 'library/utilities/withDndContext';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'library/common/commonComponents/ReactBigScheduler';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { scrollToDateOnCalendar, scrollToTop , scrollToDateOnMonthlyCalendar} from 'library/utilities/scrollActions';
import { getStatusConstantByKey } from 'library/utilities/constants';

import './appSchedularStyles.scss';
import { schedularConfig } from './schedularConfig';
import RightHeader from './components/RightHeader/RightHeader';
import LeftHeader from './components/LeftHeader/LeftHeader';
import MultiSelectKitsPopup from './components/MultiSelectKitsPopup';
import Button from '../Button/Button';
import AddMorePopover from 'library/common/commonComponents/ReactBigScheduler/AddMorePopover';
import SchedulerDataV2 from '../ReactBigScheduler/SchedulerDataV2';

const CellUnits = {
  Day: 0,
  Hour: 1,
};

class AppScheduler extends Component {
  constructor(props) {
    super(props);
    this.currentMap = [];
    this.currentMapDates = [];
    this.state = {
      currentDate: moment().format('YYYY-MM-DD'),
      eventsLoaded: false,
      viewModel: null,
      selectedKits: [],
      event: {},
      schedulerData: [],
      eventIds:[],
      operatorViewModel: null,
      operatorEvent: {},
      operatorSchedulerData: [],
      isMonthView: true,
      zmccCurrentViewType: ViewTypes.Month,
      headerItem: undefined,
      left: 0,
      top: 0,
      height: 0,
    };
  }

  componentDidMount() {
    if (this.props.isZMCCCalendar === true) {
      this.setDataForZmccCalendar();
      this.setDataForCalendarOperator();
    }else{
      this.setDataForCalendar();
    }
    if(this.props.startDate && this.props.isCalendarFromOperatorSchedules){
      this.setDataForZmccCalendar(this.props.startDate);
      this.setDataForCalendarOperator(this.props.startDate);
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { cart, alreadyBooked, resources, updateEventsOnChange, viewTypeFromProps, operatorsList } = this.props;
    if (!isEqual(resources, prevProps.resources) || (this.props.isZMCCCalendar && !isEqual(operatorsList, prevProps.operatorsList))) {
      if(this.props.isZMCCCalendar){
        this.setDataForZmccCalendar(this.state.currentDate)
        this.setDataForCalendarOperator(this.state.currentDate);
      }else{
        this.setDataForCalendar(this.props);
      }
    }
    else if (updateEventsOnChange) {
      if (!isEqual(cart, prevProps.cart) || !isEqual(alreadyBooked, prevProps.alreadyBooked)) {
        const { viewModel } = this.state;
        viewModel.setEvents(sortBy([...(alreadyBooked || []), ...(cart || [])], (item) => item.start));
        this.setState({viewModel});
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if(this.props.isZMCCCalendar && this.props.isCalendarFromOperatorSchedules){
      this.setDataForZmccCalendar(this.state.currentDate)
      this.setDataForCalendarOperator(this.state.currentDate);
    }
  }

  setDataForCalendar() {
    const { alreadyBooked, cart, events, resources , isZMCCCalendar, isLabsLocation} = this.props;
    let calendarStartDate = this.props && this.props.startDate ? new moment().subtract(moment().date(), 'days').format(DATE_FORMAT): new moment().subtract(moment().date()-1, 'days').format(DATE_FORMAT);
    let schedulerData = new SchedulerData(
      calendarStartDate,
      ViewTypes.Custom1,
      false,
      false,
      {
        headerEnabled: false,
        customCellWidth: 30,
        nonAgendaDayCellHeaderFormat: 'M/D|HH:mm',
        views: [
          {
            viewName: 'Day',
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      },
      {
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime,
      },
    );
    schedulerData.localeMoment.locale('en');
    schedulerData.setResources(resources);
    schedulerData.setEvents([...(alreadyBooked || []), ...(events || []), ...(cart || [])]);
    schedulerData.config = schedularConfig(this.props.hideOptions, ViewTypes.Custom1, isZMCCCalendar, isLabsLocation);
    this.setState({ eventsLoaded: true, viewModel: schedulerData }, () => {
      if (this.props.startDate) {
        setTimeout(() => {
          this.scrollToDate(schedulerData, this.props.startDate);
        }, 500);
      } else {
        this.calculateMap(schedulerData);
      }
    });
  }


  setDataForCalendarOperator(currentDate = null) {
    const { alreadyBooked, cart, events, resources, operatorsList, isZMCCCalendar, isLabsLocation } = this.props;
    const resourseDataForOperator = operatorsList && operatorsList.length > 0 ? operatorsList.map(item => ({...item, id:item.accountId, name: `${item.firstName} ${item.lastName}`})): [];

    let schedulerData = new SchedulerData(
      currentDate === null ? moment().format(DATE_FORMAT) : moment(currentDate).format(DATE_FORMAT),
      this.state.zmccCurrentViewType,
      false,
      false,
      {
        headerEnabled: false,
        customCellWidth: 30,
        nonAgendaDayCellHeaderFormat: 'M/D|HH:mm',
        dayStartFrom: 8,
        dayStopTo: 18,
        minuteStep: 30,
        views: [
          {
            viewName: 'Day',
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: 'Week',
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
            dayStartFrom: 8,
            dayStopTo: 17,
            daysToShow: 5,

          },
          {
            viewName: 'Month',
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
      ],
      },
      {
        getCustomDateFunc: this.getCustomDateForZmcc,
        isNonWorkingTimeFunc: this.isNonWorkingZmccTime,
      },
    );
    schedulerData.localeMoment.locale('en');
    schedulerData.setResources(resourseDataForOperator);
    schedulerData.setEvents(this.createOperatorEvents());
    schedulerData.config = schedularConfig(this.props.hideOptions, this.state.zmccCurrentViewType, isZMCCCalendar, isLabsLocation);
    schedulerData.isZmcc = true
    const element = document.documentElement;
    schedulerData._setDocumentWidth(element.clientWidth - schedularConfig(false).horizontalPadding)
    if(this.props.isCalendarFromOperatorSchedules){
      schedulerData._setDocumentWidth(element.clientWidth * 0.8 - schedularConfig(false).horizontalPadding)
    }
    if(this.props.isCalendarFromOperatorSchedules){
      schedulerData.config.schedulerWidth = '103%'
      if(window.innerWidth > 2500){
        schedulerData.config.schedulerWidth = '100%'
      }
    }
    schedulerData.config.isZmcc = true;
    this.setState({ eventsLoaded: true, operatorViewModel: schedulerData });
  }

  prepareDataForWithRoomsGrouping = (resources = []) => {
    const roomNamesIds = resources.reduce((accumulator, currentItem) => {
      accumulator.add(currentItem.roomGroupName)
      return accumulator;
    }, new Set())

    let resourceGroups = Array.from(roomNamesIds).map((room, index) => {
      let roomActualName = room.split("__")[0]
      return {id: room, name: roomActualName, groupOnly: true }
    })

    resources.forEach((resource, index) => {
      resourceGroups.push({...resource, parentId: resource.roomGroupName})
    })

    return resourceGroups;
  }

  setDataForZmccCalendar = (currentDate = null) => {
    const { alreadyBooked, cart, events, resources, isZMCCCalendar, changeLoader, isLabsLocation, isCalendarFromOperatorSchedules } = this.props;

    let config = schedularConfig(this.props.hideOptions , this.state.zmccCurrentViewType, isZMCCCalendar, isLabsLocation);

    let schedulerData = new SchedulerData(
      currentDate === null ? moment().format(DATE_FORMAT) : moment(currentDate).format(DATE_FORMAT) ,
      this.state.zmccCurrentViewType,
      false,
      false,{
        ...config,
        headerEnabled: false,
        customCellWidth: 10,
        // nonAgendaDayCellHeaderFormat: 'D/M|HH:mm',
        dayStartFrom: 8,
        dayStopTo: 18,
        minuteStep: 30,
        schedulerMaxHeight: 500,
        eventItemHeight: 30,
        tableHeaderHeight: 40,
        isZmcc: true,
        isZmccKitsCalendar: true 
      },
      {
        getCustomDateFunc: this.getCustomDateForZmcc,
        isNonWorkingTimeFunc: this.isNonWorkingZmccTime,
      },
    );
    schedulerData.localeMoment.locale('en');
    schedulerData.setResources(this.prepareDataForWithRoomsGrouping(resources));
    let allEvents = [...(alreadyBooked || []), ...(events || []), ...(cart || [])];
    let allEventsSorted = sortBy(allEvents, (item) => item.start)
    schedulerData.setEvents(allEventsSorted);
    const {zmccCurrentViewType} = this.state
    schedulerData.config.eventItemHeight = (zmccCurrentViewType === ViewTypes.WeekDay || zmccCurrentViewType === ViewTypes.Day) ? 50 : 15;
    schedulerData.config.isZmcc = true
    const element = document.documentElement;
    schedulerData._setDocumentWidth(element.clientWidth - schedularConfig(false).horizontalPadding)
    if(this.props.isCalendarFromOperatorSchedules){
      schedulerData._setDocumentWidth(element.clientWidth * 0.8 - schedularConfig(false).horizontalPadding)
    }
    if(this.props.isCalendarFromOperatorSchedules){
      schedulerData.config.schedulerWidth = '82%'
      if(window.innerWidth > 2500){
        schedulerData.config.schedulerWidth = '80.5%'
      }
    }

    schedulerData.isZmcc = true
    this.setState({ eventsLoaded: true, viewModel: schedulerData }, () => {
      if(this.state.zmccCurrentViewType === ViewTypes.Month){
        let currentDate = moment();
        if(isCalendarFromOperatorSchedules &&
          this.props.startDate &&
          moment(this.props.startDate).isValid()
        ){
          currentDate = moment(this.props.startDate);
          scrollToDateOnMonthlyCalendar(currentDate);
        }
      }
    });
  }

  mergeOverlappingIntervals(schedules) {
    const intervals = schedules.map(schedule => ({
      start: moment(schedule.startTime, 'HH:mm'),
      end: moment(schedule.endTime, 'HH:mm'),
    }));
    intervals.sort((a, b) => a.start.diff(b.start));

    const mergedIntervals = [];
    let currentInterval = intervals[0];

    for (let i = 1; i < intervals.length; i++) {
      const nextInterval = intervals[i];
      if (currentInterval.end.isSameOrAfter(nextInterval.start.subtract(moment.duration(0, 'minute'))) ){
        currentInterval.end = moment.max(currentInterval.end, nextInterval.end);
      } else {
        mergedIntervals.push(currentInterval);
        currentInterval = {...nextInterval}
      }
    }
    mergedIntervals.push(currentInterval);
    return mergedIntervals.map(interval => ({
      ...schedules.find(schedule => moment(schedule.startTime).isSame(interval.start)),
      start: interval.start.format('HH:mm'),
      end: interval.end.format('HH:mm'),
    }));
  }


  handleOperatorsDayViewSetting = (operatorsList) => {
    if (operatorsList && operatorsList.length > 0) {
      let operatorEvents = [];
      operatorsList.forEach(element => {
        const schedules = Object.keys(element.schedules);
        const schedulesObject = element.schedules
        schedules.forEach((item, index) => {
          let allActualSchedules = schedulesObject[item]
          if(allActualSchedules && allActualSchedules.length){
            const mergedIntervals = this.mergeOverlappingIntervals(allActualSchedules)
            if(mergedIntervals && mergedIntervals.length){
              mergedIntervals.forEach(interval => {
                const startDate =   moment.utc(item).format('YYYY-MM-DD')+ ` ${interval.start}`;
                const endDate = moment.utc(item).format('YYYY-MM-DD')+ ` ${interval.end}`
                let resource = {...element, id:element.accountId+index,  resourceId: element.accountId};
                const dayViewOverlappingEvents = []
                allActualSchedules.forEach((slotsAndSlotTimes) => {
                  let start = moment(slotsAndSlotTimes.startTime, 'HH:mm')
                  let end = moment(slotsAndSlotTimes.endTime, "HH:mm")
                  let intervalStart = moment(interval.start, "HH:mm")
                  let intervalEnd = moment(interval.end, "HH:mm")
                  if(start.isSameOrAfter(intervalStart) && end.isSameOrBefore(intervalEnd)){
                    dayViewOverlappingEvents.push(slotsAndSlotTimes)
                  }
                })
                let slotForTitle = dayViewOverlappingEvents.find(slot => slot.transactionId > 0) || dayViewOverlappingEvents[0];
                const title = `${slotForTitle.scheduleType} - ${slotForTitle?.title} ${dayViewOverlappingEvents.length > 1 ? "...": ""}`
                resource = {...resource, title, showPopover: true ,start: startDate, end: endDate, isOperatorSlot:true, scheduleId: slotForTitle.scheduleId, scheduleType : slotForTitle.scheduleType, dayViewOverlappingEvents, operatorSlotNotAvailable: dayViewOverlappingEvents.length > 1 ? true : (dayViewOverlappingEvents.length ==1  && slotForTitle.scheduleType == "Demo" ) ? false : true }
                operatorEvents.push(resource)
              })
            }
          }
        })
      });
      return operatorEvents;
    }
    return {}
  }

  createOperatorEvents = () => {
    const { operatorsList } = this.props;
    if(this.state.zmccCurrentViewType === ViewTypes.WeekDay || this.state.zmccCurrentViewType === ViewTypes.Day){
      return this.handleOperatorsDayViewSetting(operatorsList)
    }
    let operatorEvents = [];
    if (operatorsList && operatorsList.length > 0) {
      operatorsList.forEach(element => {
        const schedules = Object.keys(element.schedules);
        const allSchedules = element.schedules;
        schedules.forEach((item, index) => {
          const title1 = allSchedules[item].length > 1 ? `${allSchedules[item][0]?.scheduleType} - ${allSchedules[item][0]?.title} ...`:
                          `${allSchedules[item][0]?.scheduleType} - ${allSchedules[item][0]?.title}`
          const startDate =   moment.utc(item).format('YYYY-MM-DD')+ " 00:00:00";
          const endDate = moment.utc(item).format('YYYY-MM-DD')+ " 23:30:00";
         operatorEvents.push(({...element,id:element.accountId+index,  resourceId: element.accountId, showPopover: true ,start: startDate, end: endDate, isOperatorSlot:true, title: title1, operatorSlotNotAvailable:  allSchedules[item].length > 1 ? true : ((allSchedules[item].length == 1 && allSchedules[item][0]?.scheduleType ==='Demo') ? false : true)  }));
        })
      });
    }
    return operatorEvents;
  }

  calculateMap = (schedulerData, scrollToDate) => {
    if (schedulerData && schedulerData.headers && schedulerData.headers.length) {
      let startDate = moment(schedulerData.headers[0].time);
      let endDate = moment(schedulerData.headers[schedulerData.headers.length - 1].time);
      let currentMap = [];
      let currentMapDates = [];
      let i = 0;
      while (startDate.isBefore(endDate)) {
        let date = startDate.format('YYYY-MM-DD');
        currentMap[i] = date;
        currentMapDates[date] = i;
        startDate.add(1, 'day');

        if (scrollToDate === date) {
          const element = document.getElementById('content-wrapper');
          element.scrollLeft = currentMapDates[scrollToDate] * 50;
        }
        i++;
      }
      this.currentMap = currentMap;
      this.currentMapDates = currentMapDates;
    }
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    let selectDate = schedulerData.startDate;
    if (date !== undefined) selectDate = date;
    selectDate = new moment().subtract(moment().date() - 1, 'days').format(DATE_FORMAT);
    let startDate = selectDate,
      endDate = schedulerData.localeMoment(startDate).add(6, 'month').format(DATE_FORMAT),
      cellUnit = CellUnits.Day;
    if (num === 1) {
      startDate = schedulerData.startDate;
      endDate = schedulerData.localeMoment(schedulerData.endDate).add(6, 'month').format(DATE_FORMAT);
    } else if (num === -1) {
      startDate = schedulerData.localeMoment(schedulerData.startDate).add(-6, 'month').format(DATE_FORMAT);
      endDate = schedulerData.endDate;
    }
    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  getCustomDateForZmcc = (schedulerData, num, date = undefined) => {
    const {viewType} = schedulerData;
    let selectDate = schedulerData.startDate;
    if(date != undefined)
        selectDate = date;

    let startDate = num === 0 ? selectDate :
        schedulerData.localeMoment(selectDate).add(1*num, 'days').format(DATE_FORMAT),
        endDate = schedulerData.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT),
        cellUnit = CellUnits.Hour;

    if (viewType === ViewTypes.Week || viewType === ViewTypes.WeekDay) {
      let monday = schedulerData.localeMoment(selectDate).startOf('week').day(1); // Start from Monday
      startDate = num === 0 ? monday : monday.clone().add(1 * num, 'weeks');
      endDate = startDate.clone().add(4, 'days');
      cellUnit = CellUnits.Hour;
    } else if(viewType === ViewTypes.Custom1) {
        let monday = schedulerData.localeMoment(selectDate).startOf('week').format(DATE_FORMAT);
        startDate = num === 0 ? monday : schedulerData.localeMoment(monday).add(1*num, 'weeks').format(DATE_FORMAT);
        endDate = schedulerData.localeMoment(startDate).add(1, 'weeks').endOf('week').format(DATE_FORMAT);
        cellUnit = CellUnits.Day;
    } else if(viewType === ViewTypes.Custom2) {
        let firstDayOfMonth = schedulerData.localeMoment(selectDate).startOf('month').format(DATE_FORMAT);
        startDate = num === 0 ? firstDayOfMonth : schedulerData.localeMoment(firstDayOfMonth).add(1*num, 'months').format(DATE_FORMAT);
        endDate = schedulerData.localeMoment(startDate).endOf('month').format(DATE_FORMAT);
        cellUnit = CellUnits.Day;
    }

    return {
        startDate,
        endDate,
        cellUnit
    };
  }

  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === 1) {
      let hour = localeMoment(time).hour();
      if (hour < 1) return true;
    } else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 0 || dayOfWeek === 6) return true;
    }
    return false;
  };

  isNonWorkingZmccTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if(schedulerData.cellUnit === CellUnits.Hour){
        let hour = localeMoment(time).hour();
        if(hour < 1)
            return true;
    }
    else {
        let dayOfWeek = localeMoment(time).weekday();
        if (dayOfWeek === 0 || dayOfWeek === 6)
            return true;
    }

    return false;
}

  prevClick = schedulerData => {
    this.onContentScrollToDate(10);
    const { currentDate } = this.state;
    const {changeLoader} = this.props;
    schedulerData.startDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
    schedulerData.prev();
    schedulerData.setEvents([...this.props.events, ...this.props.alreadyBooked, ...this.props.cart]);
    this.calculateMap(schedulerData);
    this.setState({
      viewModel: schedulerData,
      currentDate: schedulerData.startDate,
    });
    setTimeout(() => {
      changeLoader(false);
    }, 500);
  };

  nextClick = schedulerData => {
    const { currentDate } = this.state;
    this.onContentScrollToDate(-10);
    const {changeLoader} = this.props;
    changeLoader(true);
    schedulerData.startDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
    schedulerData.next();
    schedulerData.setEvents([...this.props.events, ...this.props.alreadyBooked, ...this.props.cart]);
    this.calculateMap(schedulerData);
    this.setState({
      viewModel: schedulerData,
      currentDate: schedulerData.startDate,
    });
    setTimeout(() => {
      changeLoader(false);
    }, 500);
  };

  onSelectDate = (schedulerData, date) => {
    const element = document.getElementById('content-wrapper');
    let dateCheck = moment(date).format('YYYY-MM-DD');
    if (this.currentMapDates[dateCheck]) {
      element.scrollLeft = this.currentMapDates[moment(date).format('YYYY-MM-DD')] * 50;
    } else if (date && moment(date).isValid && moment(date).isAfter(moment())) {
      dateCheck = moment(date).add(1, 'mont').format('YYYY-MM-DD');
      while (!this.currentMapDates[dateCheck]) {
        schedulerData.next();
        this.calculateMap(schedulerData);
      }
      schedulerData.setEvents([...this.props.events, ...this.props.alreadyBooked, ...this.props.cart]);
      this.setState({ viewModel: schedulerData });
      setTimeout(() => {
        if (this.currentMapDates[dateCheck]) {
          element.scrollLeft = this.currentMapDates[moment(date).format('YYYY-MM-DD')] * 50;
        }
      }, 200);
    } else if (date && moment(date).isValid && moment(date).isBefore(moment())) {
      dateCheck = moment(date).subtract(1, 'mont').format('YYYY-MM-DD');
      while (!this.currentMapDates[dateCheck]) {
        schedulerData.prev();
        this.calculateMap(schedulerData);
      }
      schedulerData.setEvents([...this.props.events, ...this.props.alreadyBooked, ...this.props.cart]);
      this.setState({ viewModel: schedulerData });
      setTimeout(() => {
        if (this.currentMapDates[dateCheck]) {
          element.scrollLeft = this.currentMapDates[moment(date).format('YYYY-MM-DD')] * 50;
        }
      }, 200);
    } else {
      this.calculateMap(schedulerData);
    }
  };

  openPopup = () => {
    const $multiSelectKitsPopup = $('#multiSelectKitsPopup');
    if ($multiSelectKitsPopup && $multiSelectKitsPopup.modal) {
      $multiSelectKitsPopup.modal('show');
    }
  };

  saveMultiSelectedKits = () => {
    const { onAddNewBooking } = this.props;
    const { selectedKits, event, schedulerData } = this.state;
    const newEvents = [];
    newEvents.push(event);
    selectedKits
      .filter(item => item !== event.resourceId)
      .forEach(id => {
        const kit = schedulerData.resources.filter(resource => resource.kitInformationId === id);
        newEvents.push({
          id: event.id + id,
          start: event.start,
          end: event.end,
          title: '',
          resourceId: id,
          kit: kit[0],
          isBooked: false,
          isSelected: true,
        });
      });
    onAddNewBooking(newEvents);
  };

  eventClicked = (schedulerData, event) => {
    event = {...event, addedAtTimeStamp: new Date().getTime()}
    const { cart, onAddNewBooking, onRemoveBooking, toggleActionMessage } = this.props;
    const { selectedKits } = this.state;
    const role = get(event, 'kit.stockInformation.userRole[0].role.role', '');
    if (role.toLowerCase() === 'inbound staff') {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'viewOnlyPermissions');
      return;
    }
    if (selectedKits.length > 1 && selectedKits.find(id => id !== event.resourceId)) {
      this.openPopup();
      this.setState({ event, schedulerData });
    } else {
      let index = cart.findIndex(cartItem => cartItem.id === event.id);
      const removedCartForZmcc = fetchFromStorage(identifiers.removeAvailibilityForZmcc);
      let indexForRemoveZmcc = removedCartForZmcc && removedCartForZmcc.findIndex(cartItem => cartItem.id === event.id);

      if (!event.isBooked && !event.isCheckedDate) {
        if (event.isZMCCCalendar && indexForRemoveZmcc === -1){
          const removedCartForZmcc = fetchFromStorage(identifiers.removeAvailibilityForZmcc);
          let data =[];
          let res= removedCartForZmcc && removedCartForZmcc.find(item => item.id === event.id);
          data = removedCartForZmcc ?  res ? [...removedCartForZmcc] : [...removedCartForZmcc,{...event, isZMCCSlotAvailibility:false}] : [event];
          saveToStorage(identifiers.removeAvailibilityForZmcc, data);
          onAddNewBooking(event);
        }
        else if (indexForRemoveZmcc !== -1){
          const removedCartForZmcc = fetchFromStorage(identifiers.removeAvailibilityForZmcc);
          let data =[];
          data = removedCartForZmcc ? [...removedCartForZmcc.filter(item => item.id !== event.id)] : [event];
          saveToStorage(identifiers.removeAvailibilityForZmcc, data);
          onRemoveBooking(event);
        }
        else {
          if (index === -1) {
            onAddNewBooking(event);
          }
         else {
          onRemoveBooking(event);
        }
        }
      }
    }
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    const {isZMCCCalendar } = this.props;

    const changedEnd = moment(start).format('YYYY-MM-DD')+' 23:59:59';
    const { onSelectForBooking, onRejectBooking, onNonAvailability, isLabsLocation } = this.props;
    const checkStartDate = moment(start).isBefore(moment().subtract(1, 'd'));
    if (!checkStartDate) {
      let newFreshId = 1;
      schedulerData.events.forEach(item => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });
      const kit = schedulerData.resources.filter(resource => resource.kitInformationId === slotId);
      if(!kit || !kit.length){
        return
      }
      const { availabilityTo, availabilityFrom } = kit[0];
      const checkAvailableToDate = availabilityTo !== '' ? moment(end).isAfter(availabilityTo) : null;
      const checkAvailableFromDate = availabilityFrom !== '' ? moment(start).isBefore(availabilityFrom) : null;
      if (checkAvailableToDate || checkAvailableFromDate) {
        onNonAvailability(availabilityFrom, availabilityTo);
        return;
      }
      let newEvent = {
        id: newFreshId,
        start: start,
        end: isZMCCCalendar ? changedEnd : end,
        title: '',
        resourceId: slotId,
        kit: kit[0],
        isBooked: false,
        isSelected: true,
      };
      onSelectForBooking(newEvent, isLabsLocation);
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData,
      });
    } else {
      onRejectBooking();
    }
  };

  handleRemoveEvent(e, schedularData, event) {
    const { onRemoveBookingFromCalendar } = this.props;
    e.stopPropagation();
    onRemoveBookingFromCalendar([event]);
    schedularData.removeEvent(event);
  }

  getTransactionSatusNameByStaticCode(staticCode) {
    const statusConstant = getStatusConstantByKey(staticCode);
    const statusTranslation = strings[statusConstant];
    return statusTranslation;
  }

  updateEventStart = (schedulerData, event, newStart) => {
    const { onUpdateEvent, onRejectBooking, onNonAvailability } = this.props;
    const checkStartDate = moment(newStart).isBefore(moment().subtract(1, 'd'));
    if (!checkStartDate) {
      const kit = schedulerData.resources.filter(resource => resource.kitInformationId === event.resourceId);
      const { availabilityFrom } = kit[0];
      const checkAvailableFromDate = availabilityFrom !== '' ? moment(newStart).isBefore(availabilityFrom) : null;
      if (checkAvailableFromDate) {
        onNonAvailability('aboveAvailableFromDate');
        return;
      }
      schedulerData.updateEventStart(event, newStart);
      const updatedEvent = { ...event };
      updatedEvent['start'] = newStart;
      onUpdateEvent(updatedEvent);
    } else {
      onRejectBooking();
    }
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    const { onUpdateEvent, onNonAvailability } = this.props;
    const kit = schedulerData.resources.filter(resource => resource.kitInformationId === event.resourceId);
    const { availabilityTo } = kit[0];
    const checkAvailableToDate = availabilityTo !== '' ? moment(newEnd).isAfter(availabilityTo) : null;
    if (checkAvailableToDate) {
      onNonAvailability('aboveAvailableToDate');
      return;
    }
    schedulerData.updateEventEnd(event, newEnd);
    const updatedEvent = { ...event };
    updatedEvent['end'] = newEnd;
    onUpdateEvent(updatedEvent);
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    const { onUpdateEvent, onRejectBooking } = this.props;
    const checkStartDate = moment(start).isBefore(moment().subtract(1, 'd'));
    const isLabsLocation = event.kit.stockInformation.businessUnit.designation == 'RMS_L_L';
    const isZMCCCalendar = event.kit.stockInformation.businessUnit.designation == 'RMS_ZMCC';
    if (!checkStartDate) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      const updatedEvent = { ...event, isLabsLocation , isZMCCCalendar, isAvailabilityChecked: true      };
      updatedEvent['resourceId'] = slotId;
      onUpdateEvent(updatedEvent);
    } else {
      onRejectBooking();
    }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (
      moment(`${moment().add(this.props.maxInFuture, 'months').format('YYYY-MM-DD')} 12:00.000`).isAfter(
        moment(`${moment(schedulerData.endDate).format('YYYY-MM-DD')} 12:00.000`),
      )
    ) {
      schedulerData.next();
      schedulerData.setEvents([...this.props.events, ...this.props.alreadyBooked, ...this.props.cart]);
      this.calculateMap(schedulerData);
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (
      moment(`${moment().subtract(this.props.maxInPast, 'months').format('YYYY-MM-DD')} 12:00.000`).isBefore(
        moment(`${moment(schedulerData.startDate).format('YYYY-MM-DD')} 12:00.000`),
      )
    ) {
      const date = moment(schedulerData.headers[0].time).subtract(2, 'days').format('YYYY-MM-DD');
      schedulerData.prev();
      this.calculateMap(schedulerData, moment(date).format('YYYY-MM-DD'));
      schedulerData.setEvents([...this.props.events, ...this.props.alreadyBooked, ...this.props.cart]);
    }
  };

  scrollToDate = (schedulerData, date) => {
    this.onSelectDate(schedulerData, date);
  };

  onScrollTop = () => {};

  onScrollBottom = () => {};

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
  };

  conflictOccurred = (schedulerData, action, event, type, slotId, slotName, start, end) => {
    const { onConflictOccurred } = this.props;
    onConflictOccurred(true);
  };

  removeSelectedKits = () => {
    this.setState({ selectedKits: [] });
  };

  setBgcolorForZmccTransactions = (event) =>{
      const intenedStaticValues = ["Administration","Tool_owner_work","Marketing_support", "Product_Center_support_app_development", "Product_Center_support_apps_training", "Product_Center_support_hard_software_tests", "Sales_support" ]
      if(event.isZMCCCalendar){
        if(event.isRefurbishment){
          return "#808080"
        }else if(event?.transactionStatusId){
          let statusType = event.transactionStatusId;
          if(statusType === 13 || statusType == 6){
            return "#63BA3C"
          } else if(statusType === 11 || statusType === 12 || statusType === 1 ){
            return "#FF0000"
          } else if (statusType === 10 || intenedStaticValues.includes(event.intendedForStaticValue)){
            return "#057df5"
          } else if(statusType === 14){
            return "rgba(191, 64, 191, 0.5)"
          }
        }
      }
  }

  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth,
  ) => {
    const fixedHeightForSlot = schedulerData.config.fixedHeightForSlot;

    const {eventIds, zmccCurrentViewType} = this.state;
    eventIds.push(event.id);
    const dataFromCart = fetchFromStorage(identifiers.cart);
    const setISZMCCSlotAvailibility =() =>{
      let result = true;
      if (dataFromCart && dataFromCart.length >0){
        dataFromCart.map(each => {
          if (each.hasOwnProperty("isZMCCSlotAvailibility")){
            if (each.id === event.id){
              result = false;
              return result;
            }
          }
        })
      }
      return result;
    }

    const dataFromRemovingCart = fetchFromStorage(identifiers.removeAvailibilityForZmcc);
    const setRemoveZMCCSlotAvailibility =() =>{
      let result = true;
      if (dataFromRemovingCart && dataFromRemovingCart.length >0){
        dataFromRemovingCart.map(each => {
          if (each.hasOwnProperty("isZMCCSlotAvailibility")){
            if (each.id === event.id){
              result = false;
              return result;
            }
          }
        })
      }
      return result;
    }


    const { cart, isLabsLocation, isZMCCCalendar } = this.props;
    const workflows = get(event, 'kit.stockInformation.workflowCategoryAnswers');
    let isAutoApproveAllowed = false;

    if (workflows && workflows.length) {
      workflows.forEach(workflow => {
        const { workflowCategoryQuestionsId } = workflow.workflowCategoryQuestions;
        if (workflowCategoryQuestionsId === 5) {
          isAutoApproveAllowed = workflow.value === 'yes';
        }
      });
    }

    let indexOfItem = cart && cart.findIndex(cartItem => cartItem.id === event.id);
    let backgroundColor = '#80C5F6';
    let titleText = '';
    let iconName = '';

    const differenceOfDays = moment(event.end).diff(moment(event.start), 'd') + 1;

    if (isAutoApproveAllowed) {
      backgroundColor = '#4ab300';
      titleText = isLabsLocation? strings.addRequest:strings.addToLoanList;
      iconName = 'addLoanIcon';
    } else {
      backgroundColor = '#ff1a00';
      titleText = isLabsLocation?strings.requestDemo:strings.requestLoan;
      iconName = 'info';
    }

    if (indexOfItem !== -1 && !isZMCCCalendar) {
      backgroundColor = '#747678';
      titleText = strings.removeFromLoanList;
      iconName = 'removeLoanIcon';
    }

    if (event.type === 'deliveryPeriod') {
      backgroundColor = '#4ab300';
      titleText = strings.delivery;
      iconName = '';
    } else if (event.type === 'loanPeriod') {
      backgroundColor = '#747678';
      titleText = strings.loanPeriod;
      iconName = '';
    } else if (event.type === 'returnDeliveryPeriod') {
      backgroundColor = '#4ab300';
      titleText = strings.returnDelivery;
      iconName = '';
    }

    let divStyle = { backgroundColor: backgroundColor, height: mustBeHeight, borderRadius: '3px' };
    if (!!agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    if (event.isRefurbishment) {
      return (
        <div key={event.id} className='booked-kit' style={{ backgroundColor: this.setBgcolorForZmccTransactions(event), height:`${fixedHeightForSlot}px`}}>
          <p>{event.id}</p>
          <div
            className='booked-kit-return-period'
            style={{
              width: 50 * event.returnDeliveryDays - 3, backgroundColor: this.setBgcolorForZmccTransactions(event), height:`${fixedHeightForSlot}px`
            }}
          >
            <div className='booked-kit-reconditioning' style={{ width: 50 * event.reconditioningDays - 4, backgroundColor: this.setBgcolorForZmccTransactions(event),  height:`${fixedHeightForSlot}px`}}/>
          </div>
        </div>
      );
    }


    if (setISZMCCSlotAvailibility() === true && event.hasOwnProperty("isZMCCSlotAvailibility") && setRemoveZMCCSlotAvailibility() === true) {
      return (
        <div key={event.id} className={`slot-booked-kit ${(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) ? 'add-height-for-slot' : ''}`} style={{height:`${fixedHeightForSlot}px`}} >
          {zmccCurrentViewType === ViewTypes.Month && <p>{event.id}</p>}
          {(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) && <p className='display-title-value'>{event.title}</p>}
          <div
            className='slot-booked-kit-blocked-period'
            style={{
              width: schedulerData.getContentCellWidth() * event.blockedDays - 3,  height:`${fixedHeightForSlot}px`
            }}
          />
        </div>
      )
    }

    const isZmccSlot = get(event, 'kit.stockInformation.designation') === 'RMS_ZMCC';
    if (isZMCCCalendar && event.isBlocked) {
      return (
        <div
          key={event.id}
          className={`${
            zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay
              ? 'add-height-for-booked-slot'
              : ''
          }`}
          style={{
            height: `${mustBeHeight}px`,
            borderRadius: isZMCCCalendar ? '25px' : '3px',
            backgroundColor: '#9a9b9c',
          }}
        >
          <p style={{ color: 'transparent' }}>{event.id}</p>
        </div>
      );
    }

    if (event.isBlocked) {
      return (
        <div key={event.id} className={`booked-kit ${(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) ? 'add-height-for-booked-slot' : ''}`} style={{height:`${fixedHeightForSlot}px`,  borderRadius: isZMCCCalendar ? '25px' : '3px'}}>
          <p>{event.id}</p>
          <div
            className="booked-kit-blocked-period"
            style={{
              width:
              (zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) ?  60 * 11 * event.blockedDays - 3 : schedulerData.getContentCellWidth() * event.blockedDays - 3
              ,height:`${fixedHeightForSlot}px`, borderRadius: isZMCCCalendar ? '25px' : '3px'
            }}
          />
        </div>
      );
    }

    if (event.isCheckedDate) {
      return (
        <NavLink to={'/transactions/' + event.transactionsId}>
          <div key={event.id} className='booked-kit' style={{height:`${fixedHeightForSlot}px`}}>
            <p>{event.id}</p>
            <div
              className='booked-kit-overdue-period'
              style={{
                width: schedulerData.getContentCellWidth() * event.overdueCheckedDays - 3,  height:`${fixedHeightForSlot}px`
              }}
            />
          </div>
        </NavLink>
      );
    }

    if (event.isOperatorSlot) {
      const schudlesData = Object.keys(event.schedules);
      return(
        <div
          key={event.id} className={`slot-operator-schedule ${(event.operatorSlotNotAvailable) ? 'not-show-blue-color-for-op' : 'show-blue-color-for-op'} ${(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) ? 'operator-slot-booked-height' : ''}` }
          style ={{backgroundColor: '#91b3eb'}}
        >
          {zmccCurrentViewType === ViewTypes.Month &&<p>{event.id}</p>}
          {(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) && <p className='disaply-title-value-for-operator'>{event.title}</p>}
          <div
            className='slot-operator-schedule-blocked-period'
            style={{
              width: schedulerData.getContentCellWidth() * event.blockedDays - 3,  height:`${fixedHeightForSlot}px`
            }}
          />
        </div>
      )
    }

    if (event.isBooked) {
      if (event.isTwentyFourHourReservation) {
        return (
          <NavLink to={'/open-processes/' + event.transactionsId}>
            <div key={event.id} className='booked-kit' style={{ backgroundColor: '#747678',  height:`${fixedHeightForSlot}px` }}>
              <p>{event.id}</p>
            </div>
          </NavLink>
        );
      } else {
        let link = '/transactions/' + event.transactionsId;
        let setBlueColourForLongBooking;
        let pastLongerBooking = false;
        let startDate = moment(schedulerData.headers[0].time);
        let endDate = moment(schedulerData.headers[schedulerData.headers.length - 1].time);
        if (moment(event.targetReservationFrom).format('YYYY-MM-DD') < moment(startDate).format('YYYY-MM-DD')) {
          pastLongerBooking = true;
        }
        if (moment(event.targetReservationTo).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')) {
          setBlueColourForLongBooking = true;
        }else{
          setBlueColourForLongBooking = false
        }
        if (event.nextC2cId || event.prevC2cId) {
          link = `/c2c/${event.transactionsId}/view-c2c-loan`;
        }
        return (
          <>
            {event.isZMCCCalendar ? (
              <NavLink to={`/transactions/${event.transactionsId}/isZmcc`}>
                <div
                  key={event.id}
                  className={cn(
                    // `${
                    //   zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay
                    //     ? ''
                    //     : ''
                    // }`,
                    { 'radius-right': event.nextC2cId, 'radius-left': event.prevC2cId },
                  )}
                  style={{
                    height: mustBeHeight,
                    borderRadius: isZMCCCalendar ? '25px' : '3px',
                  }}
                >
                  {this.renderZmccRequestSlot(event, schedulerData, mustBeHeight, mustAddCssClass)}
                  {/* {zmccCurrentViewType === ViewTypes.Month && <p>{event.id}</p>}
                  {(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) && (
                    <p className='display-value-for-booked-kit'>{event.title}</p>
                  )} */}
                  {!isLabsLocation && (
                    <>
                      {!pastLongerBooking && (
                        <div
                          className={cn('booked-kit-delivery-period', { 'radius-left': event.prevC2cId })}
                          style={{ width: 50 * event.deliveryDays, height: `${fixedHeightForSlot}px` }}
                        />
                      )}
                      {!setBlueColourForLongBooking && (
                        <div
                          className='booked-kit-return-period'
                          style={{
                            width: 50 * event.returnDeliveryDays - 3,
                            height: `${fixedHeightForSlot}px`,
                          }}
                        >
                          <div
                            className='booked-kit-reconditioning'
                            style={{ width: 50 * event.reconditioningDays - 4, height: `${fixedHeightForSlot}px` }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </NavLink>
            ) : (
              <NavLink to={link}>
                <div
                  key={event.id}
                  className={cn('booked-kit', { 'radius-right': event.nextC2cId, 'radius-left': event.prevC2cId })}
                  style={{ height: `${fixedHeightForSlot}px` }}
                >
                  <p>{event.id}</p>
                  {!isLabsLocation && (
                    <>
                      {!pastLongerBooking && (
                        <div
                          className={cn('booked-kit-delivery-period', { 'radius-left': event.prevC2cId })}
                          style={{ width: 50 * event.deliveryDays, height: `${fixedHeightForSlot}px` }}
                        />
                      )}
                      {!setBlueColourForLongBooking && (
                        <div
                          className='booked-kit-return-period'
                          style={{
                            width: 50 * event.returnDeliveryDays - 3,
                            height: `${fixedHeightForSlot}px`,
                          }}
                        >
                          <div
                            className='booked-kit-reconditioning'
                            style={{ width: 50 * event.reconditioningDays - 4, height: `${fixedHeightForSlot}px` }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </NavLink>
            )}
          </>
        );
      }
    }
    return (
      <div
        key={event.id}
        className={`single-event ${differenceOfDays < 3 ? 'justify-content-center' : ''} ${mustAddCssClass}`}
        style={divStyle}
      >
        {differenceOfDays >= 3 && <div className='selected-from-date'>{moment(event.start).format('D MMM')}</div>}
        <div className='event-title' style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>
          <Icon name={iconName} width={16} height={16} fill='#fff' />
          {differenceOfDays > 4 && <span className='ml-1'>{titleText}</span>}
        </div>
        <div className='d-flex justify-content-center align-content-center'>
          {indexOfItem === -1 && differenceOfDays > 2 && (
            <div className='selected-to-date'>
              <button className='delete-button' onClick={e => this.handleRemoveEvent(e, schedulerData, event)}>
                <Icon name='cross' width={16} height={16} fill='#fff' />
              </button>
            </div>
          )}
          {differenceOfDays >= 3 && (
            <div className='selected-to-date'>
              <p className={indexOfItem === -1 ? 'to-date-text' : ''}>{moment(event.end).format('D MMM')}</p>
            </div>
          )}
        </div>
        {
          (isZMCCCalendar && indexOfItem === -1 ) &&
          <div className='selected-to-date'>
          <button className='delete-button' onClick={e => this.handleRemoveEvent(e, schedulerData, event)}>
            <Icon name='cross' width={16} height={16} fill='#fff' />
          </button>
        </div>
        }
      </div>
    );
  };

  renderZmccRequestSlot = (event, schedulerData, height, className) => {
    const {postPreparationPeriod, prePreparationPeriod} = event;
    const {zmccCurrentViewType} = this.state;
    const pre = Number(prePreparationPeriod) || 0;
    const post = Number(postPreparationPeriod) || 0;

    const date = moment(event.suggestedDateFrom);
    const startOfMonth = date.clone().startOf('month');
    const endOfMonth = date.clone().endOf('month');
    let daysFromStart = date.diff(startOfMonth, 'days');
    const daysToEnd = endOfMonth.diff(date, 'days');
    let maxPre = pre < daysFromStart ? pre : daysFromStart;
    let isCurrent = moment(schedulerData.startDate).month() === moment(event.suggestedDateFrom).month();
    if(this.state.zmccCurrentViewType === ViewTypes.WeekDay){
      let suggestedDayWeekStart = moment(event.suggestedDateFrom).startOf('week');
      daysFromStart = date.diff(suggestedDayWeekStart, 'days') - 1; // -1 because sunday is start
      maxPre = pre < daysFromStart ? pre : daysFromStart;
      isCurrent = moment(schedulerData.startDate).week() === moment(event.suggestedDateFrom).week();
    }else if(this.state.zmccCurrentViewType === ViewTypes.Day){
      isCurrent = moment(schedulerData.startDate).format('YYYY-MM-DD') === moment(event.suggestedDateFrom).format('YYYY-MM-DD');
      maxPre = isCurrent ? 0 : 1;
    }
    let cellWidth = zmccCurrentViewType === ViewTypes.Month ? 50 : schedulerData.getContentCellWidth() * 2 *11;
    return (
      <div
        className={className}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: `${pre || post ? '#E0E1DD' : '#fff'}`,
          height: height,
          overflow: 'hidden',
          borderRadius: zmccCurrentViewType === ViewTypes.Month ? 25: 0,
        }}
      >
        <div
          style={{
            width: cellWidth,
            height: height,
            backgroundColor: isCurrent ? this.setBgcolorForZmccTransactions(event): "#E0E1DD",
            flexShrink: 0,
            borderRadius: zmccCurrentViewType === ViewTypes.Month ? 25: 0,
            position: 'relative',
            left: maxPre * cellWidth,
            textAlign: "center"
            // marginLeft: `${(pre || post) ? 9: 0}px`,
          }}
        >
          {(zmccCurrentViewType === ViewTypes.Day || zmccCurrentViewType === ViewTypes.WeekDay) && isCurrent &&(
            <p className='display-value-for-booked-kit' style={{lineHeight: `${height}px`}}>{event.title}</p>
          )} 
        </div>
      </div>
    );
  }

  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
    const { cart,isLabsLocation, isZMCCCalendar, generalTransactionStatuses, llTransactionStatuses } = this.props;
    const index = cart.findIndex(cartItem => cartItem.id === eventItem.id);
    const differenceOfDays = end.diff(start, 'd') + 1;
    let message = '';
    let messageBorrower = '';
    let messageStockController = '';
    let messageCustomer = '';
    let comment = '';
    let organization = '';
    let transactionId = '';
    let city = '';
    let state = '';
    let kitName = '';
    let transactionStatus = '';
    let name = '';
    let category = '';
    let priority = '';
    let operator = '';
    let customerName = '';
    let customerInstitute = '';
    let status =''
    let prepreparationPeriod = '';
    let postPreparationPeriod = '';
    if (index === -1 && !isZMCCCalendar) {
      if (differenceOfDays < 15) {
        message = isLabsLocation? strings.addRequest:strings.addToLoanList;
      } else {
        message = strings.requestLoanPopoverMessage;
      }
    } else {
      message = strings.removeFromLoanList;
    }

    if (!eventItem.hasOwnProperty("isZMCCSlotAvailibility")){
      message = strings.addToLoanList;
    }
    if (eventItem.hasOwnProperty("isZMCCSlotAvailibility")) {
      message = 'Remove from slot availibility'
    }

    if (eventItem.isBlocked) {
      const emailAddress = (eventItem.kit && eventItem.kit.stockInformation && eventItem.kit.stockInformation.email) || '-';
      message = strings.formatString(strings.kitIsNoLongerAvailable, { date: moment(eventItem.start).format('DD-MM-YYYY'), emailAddress });
    }
    if ((eventItem.isBooked || eventItem.isCheckedDate)) {
      if (eventItem.isTwentyFourHourReservation) {
        message = strings.formatString(strings.reservedKitMessage, {
          from: moment(start).format('DD-MM-YYYY'),
          to: moment(end).format('DD-MM-YYYY'),
        });
      } else {
        if (!isZMCCCalendar) {
          message = strings.formatString(strings.alreadyBookedKitMessage, {
            from: moment(start).format('DD-MM-YYYY'),
            to: moment(end).format('DD-MM-YYYY'),
          });
        }else{
          message =''
        }

      }
      if (eventItem.isCheckedDate) {
        message = strings.formatString(strings.delayWarning, { date: moment(start).format('DD-MM-YYYY') });
      }
      messageBorrower = strings.formatString(strings.alreadyBookedKitBorrower, {
        borrower: eventItem.borrower ? eventItem.borrower : '-',
      });
      messageCustomer = eventItem.customerName
        ? strings.formatString(strings.alreadyBookedKitCustomer, { customerName: eventItem.customerName })
        : '';
      messageStockController = strings.formatString(strings.alreadyBookedKitStockController, {
        stockController: eventItem.stockController ? eventItem.stockController : '-',
      });
      comment = eventItem.comment
        ? strings.formatString(strings.alreadyBookedComment, { comment: eventItem.comment })
        : '';
      organization = `${strings.organization}: ${eventItem.organization}`;
      transactionId = `${strings.transactionId}: ${eventItem.transactionsId}`;
      state = `${eventItem && eventItem.state ? eventItem.state : ''}`;
      city = `${strings.city}${state && ','} ${state !== ''? strings.state : ''}: ${eventItem && eventItem.city}`
      kitName = `${strings.kitName}: ${eventItem.kit.kitName} ${eventItem.kit.serialNumber?`(${eventItem.kit.serialNumber})`:''}`
      if(isLabsLocation){
        let statusResult = llTransactionStatuses && llTransactionStatuses.find((status) => status.transactionStatusId === eventItem.transactionStatusId).name;
        transactionStatus = `${strings.transactionStatus}: ${statusResult}`
      } else if (!isLabsLocation && !isZMCCCalendar){
        const medBusinessUnits = ['MED', 'MED-OPT', 'MED-SUR'];
        let statusResultingen = generalTransactionStatuses && generalTransactionStatuses.find((status) => status.transactionStatusId === eventItem.transactionStatusId);
        if(statusResultingen && statusResultingen.transactionStatusId === 1){
          if(statusResultingen.name == "Request"){
            statusResultingen.name = "Requested";
          } else if(statusResultingen.name == "Anfrage"){
            statusResultingen.name = "Angefordert";
          }
        }

        if (medBusinessUnits.includes(eventItem.businessUnitDesignation) && eventItem.countryShortName == "US" && statusResultingen){
          if(statusResultingen.transactionStatusId === 3){
            statusResultingen.name = strings.confirmed;
          }
          if(statusResultingen.transactionStatusId === 9){
            statusResultingen.name = strings.transactionDelivered;
          }
        } else{
          if(statusResultingen.transactionStatusId === 3){
            statusResultingen.name = strings.deliver;
          }
        }
        transactionStatus = `${strings.transactionStatus}: ${statusResultingen.name}`
      }

      if (isZMCCCalendar) {
        name = `${strings.name}: ${eventItem.name || '-'}`;
        priority = `${strings.priority}: ${eventItem.priority || '-'}`;
        category = `${strings.category}: ${eventItem.category || '-'}`;
        customerName = `${strings.customerName}: ${eventItem.customerName || '-'}`;
        customerInstitute = `${strings.customerInstitute}: ${eventItem.customerInstitute || '-'}`;
        status = `${strings.status}: ${this.getTransactionSatusNameByStaticCode(eventItem.status)}`;
        operator = `${strings.operator}: ${eventItem.operator.join(', ')}`
        prepreparationPeriod = `${strings.prePreprationPeriod}: ${eventItem.prePreparationPeriod || '-'}`;
        postPreparationPeriod = `${strings.postPreparationPeriod}: ${eventItem.postPreparationPeriod || '-'}`;
      }
    }
    if (eventItem.prevC2cId || eventItem.nextC2cId) {
      comment = strings.formatString(strings.c2cloanTooltip)
    }
    if (eventItem.isRefurbishment) {
      const kitReparationPeriod = eventItem.kit.kitReparation && eventItem.kit.kitReparation.length  &&  eventItem.kit.kitReparation.filter(item => item.isReparationTimeActive === true);
      message = strings.kitInReparation;
      //As per currentImplementation one period of kit reparation is active so we are taking 0 position
      comment = kitReparationPeriod && kitReparationPeriod.length > 0 && kitReparationPeriod[0].reparationComment ? strings.formatString(strings.kitInReparationComment, { comment: kitReparationPeriod[0].reparationComment }) : ''
    }

    if (eventItem.isSlotNotAvailable) {
      message = strings.kitslotIsnotValiable;
    }

    const transactionNumber = eventItem.transactionNumber;

    return (
      <div className='event-popover'>
        { <p>{message}</p>}
        {(transactionNumber || transactionId) && <p>{transactionNumber ? `Transaction ID: `+transactionNumber : transactionId}</p>}
        { !isZMCCCalendar && transactionStatus && <p>{transactionStatus}</p>}
        { !isZMCCCalendar && messageBorrower && <p>{messageBorrower}</p>}
        { !isZMCCCalendar && messageStockController && <p>{messageStockController}</p>}
        { !isZMCCCalendar && messageCustomer && <p>{messageCustomer}</p>}
        { !isZMCCCalendar && organization && <p>{organization}</p>}
        { !isZMCCCalendar && city && <p>{city}{state && ','} {state}</p>}
        { !isZMCCCalendar && kitName && <p>{kitName}</p>}
        {isZMCCCalendar && name && <p>{name}</p>}
        {isZMCCCalendar && category && <p>{category}</p>}
        {isZMCCCalendar && priority && <p>{priority}</p>}
        {isZMCCCalendar && eventItem.operator && <p style={{wordWrap:'break-word'}}>{operator}</p>}
        {isZMCCCalendar && status && <p>{status}</p>}
        {isZMCCCalendar &&  customerName && <p>{customerName}</p>}
        {isZMCCCalendar &&  customerInstitute && <p>{customerInstitute}</p>}
        {isZMCCCalendar &&  customerInstitute && <p>{customerInstitute}</p>}
        {isZMCCCalendar &&  prepreparationPeriod && <p>{prepreparationPeriod}</p>}
        {isZMCCCalendar &&  postPreparationPeriod && <p>{postPreparationPeriod}</p>}
        { !isZMCCCalendar && comment && <p>{comment}</p>}
      </div>
    );
  };

  OperatorPopOver = (schedulerData, eventItem, title, start, end, statusColor) => {
    const startDate = moment(eventItem.start).format('YYYY-MM-DD');
    const scheduesData = eventItem.schedules && eventItem.schedules[startDate];
    if (this.state.zmccCurrentViewType === ViewTypes.Day || this.state.zmccCurrentViewType === ViewTypes.WeekDay) {
      return (
        <>
          {eventItem.dayViewOverlappingEvents.length > 0 && eventItem.dayViewOverlappingEvents.map((item, index) =>  <div key={`event-key-${index}`}>{`${moment(item.startTime, 'HH:mm').format('h:mm A')}-${moment( item.endTime,'HH:mm').format('h:mm A')} (${item.transactionId ? item.scheduleType +" - "+ item.title : item.scheduleType})`}</div>)}
        </>
      )
    }
    return(
      <>
     {scheduesData && scheduesData.length > 0 && scheduesData.map((item, index) =>  <div key={`event-key-${index}`}>{`${moment(item.startTime, 'HH:mm').format('h:mm A')}-${moment( item.endTime,'HH:mm').format('h:mm A')} (${item.transactionId ? item.scheduleType +" - "+ item.title : item.scheduleType})`}</div>)}
      </>
    )
  }

  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    const {isLabsLocation, isZMCCCalendar} = this.props;
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = datetime.isSame(new Date(), 'day');
    if (isCurrentDate) {
      style.color = '#4a90e2';
      style.fontWeight = 'bold';
      style.fontSize = '12px';
    }

    let day = moment(item.time).format('ddd');
    let date = moment(item.time).format('D.MM');
    return (
      (!isLabsLocation && !isZMCCCalendar) ?
      <th key={item.time} className='header3-text' style={style}>
        <div>{day}</div>
        <div>{date}</div>
      </th>
      :
      <th key={item.time} className='header3-text' style={style}>
        {formattedDateItems.map((formattedItem, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>') }} />
        ))}
      </th>
    );
  };


  nonAgendaCellHeaderTemplateResolverForZmcc = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = datetime.isSame(new Date(), 'day');
    let isCurrentTime = moment(datetime).isSame(new Date(), "hour")
    const { isCalendarFromOperatorSchedules, startDate, eventFromTo } = this.props;
    if (isCurrentTime) {
      style.color = '#fff';
      style.backgroundColor = 'rgb(0, 136, 208)';
      style.fontWeight = 'bold';

      if (startDate && moment(startDate).isValid() && isCalendarFromOperatorSchedules) {
        style.color = "#63ba3c";
        style.backgroundColor = '#fff';
      }
    } else if (isCurrentDate) {
      style.color = '#4a90e2';
      style.fontWeight = 'bold';
      style.fontSize = '12px';

      if (startDate && isCalendarFromOperatorSchedules && moment(startDate).isValid() && !moment(startDate).isSame(new Date(), "hour")) {
        style.color = "#63ba3c";
      }
    }
    let isSuggestedDate = startDate && isCalendarFromOperatorSchedules && moment(startDate).isValid() && datetime.isSame(startDate, 'day');
    if (isSuggestedDate) {
      style.color = '#4a90e2';
      style.fontWeight = 'bold';
      style.fontSize = '12px';

      if (eventFromTo && eventFromTo.length === 2 &&
          eventFromTo[0] && eventFromTo[1] ) {
        let start = moment(eventFromTo[0], 'HH:mm');
        let end = moment(eventFromTo[1], 'HH:mm');
        if (start.isValid() && end.isValid()) {
          let timeOnly = moment(datetime).format("HH:mm");
          let timeMoment = moment(timeOnly, 'HH:mm');
          if (timeMoment.isBetween(start, end, null, '[]')) {
            style.color = '#4a90e2';
            style.backgroundColor = '#fff';
          }else{
            style.color = "#000"
            style.backgroundColor = '#fff';
            style.fontWeight = 'normal';
          }
        }
      }
    }

    let days = [];
    let day = '';
    let date;
    if (moment(item.time).isValid()) {
      date = moment(item.time).isValid() && moment(item.time).format('YYYY-MM-DD');
      day = moment(date).format('ddd');
      date = moment(date).format('DD')
      days.push({date: date, day: day});
    }

    const getFormattedTime = (time) => {
      try{
        return moment(time, "ha").isValid() ? moment(time, "ha").format('h A') : time
      }catch(err){
        return time
      }
    }

    if(this.state.zmccCurrentViewType === ViewTypes.WeekDay){
      return (
        <th key={item.time} className='header3-text' style={style}>
          {formattedDateItems.map((formattedItem, index) => (
           <div key={index} dangerouslySetInnerHTML={{ __html:  getFormattedTime(formattedItem)}} />
          ))}
        </th>
      );
    }
    if (this.state.zmccCurrentViewType === ViewTypes.Day) {
      return (
        <th key={item.time} className='header3-text' style={style}>
          {formattedDateItems.map((formattedItem, index) => (
           <div key={index} dangerouslySetInnerHTML={{ __html:  getFormattedTime(formattedItem)}} />
          ))}
        </th>
      );
    }
    return (
      <th key={`${item.time}-${item.time}`} className='header3-text' style={style}>
        { days.map((formattedItem, index) => {
              const now = new Date()
              return(
              <>
              <div key={index} dangerouslySetInnerHTML={{ __html: formattedItem.date}} />
              <div key={`${index}-${now.getMilliseconds()}`} dangerouslySetInnerHTML={{ __html: formattedItem.day}} />
              </>
            )})
          }
      </th>
    );
  };

  slotItemTemplateResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const { events, resources, isLabsLocation, isZMCCCalendar } = this.props;
    const { selectedKits } = this.state;
    let indexOfEvent = events.findIndex(event => event.resourceId === slot.slotId);
    let fillColor = indexOfEvent > -1 ? 'rgba(0,0,0,0.2)' : '#000';
    let indexOfResource = resources.findIndex(resource => resource.kitInformationId === slot.slotId);
    let resource = resources[indexOfResource];
    const isMedUsKit = resource && resource.stockInformation && resource.stockInformation.businessUnit.name === 'MED' &&
          resource.stockInformation.country.shortName === 'US';
    const currentDate =  moment(new Date());
    const availableFrom = resource && moment(resource.availabilityFrom).format('YYYY-MM-DD');
    const age = currentDate.diff(availableFrom, 'days');
    let shortName = '';
    let infoMessage = '';
    if (resource) {
      shortName = `${
        resource && resource.stockInformation && resource.stockInformation.country !== null
          ? resource.stockInformation.country.shortName + '-'
          : ''
      }${
        resource && resource.stockInformation && resource.stockInformation.businessUnit !== null
          ? resource.stockInformation.businessUnit.name + '-'
          : ''
      }${resource && resource.stockInformation ? resource.stockInformation.locationShortName : ''}`;

      infoMessage = `${strings.deliveryDays +':' + '  ' +`${resource.specialDeliveryTime ? resource.specialDeliveryTime.deliveryDays : resource.deliveryDays}`+ ', '}${
        strings.returnDeliveryDays +':' + '  '  + `${resource.specialDeliveryTime ? resource.specialDeliveryTime.returnDeliveryDays : resource.returnDeliveryDays}` + ', '
      }${strings.reconditioningDays +':' + '  ' + resource.reconditioningDeliveryDays}`;
    }

    if(slot.groupOnly === true){
      let roomName = slot.slotName;
      return <p style={{fontWeight: 700}}>Room {roomName}</p>;
    }

    return (
      <div className={(isLabsLocation ? 'container-class' :  isZMCCCalendar ? 'container-class' : '')}>
        <div className='d-flex mb-2 pl-2'>
        {!this.props?.isCalendarFromOperatorSchedules &&
          <Checkbox
            selected={selectedKits.length && selectedKits.includes(slot.slotId)}
            field={slot.slotId}
            onChange={this.handleSelect(slot.slotId)}
            small
            customeStyledClass=
            {(!isLabsLocation && !isZMCCCalendar) && 'white-checkbox'}
          />
        }
        <NavLink
          to={`/kit_details/${slot.slotId}`}
          title={
            resource && resource.serialNumber && !isLabsLocation
              ? `${strings.serialNumber}: ${resource.serialNumber}\n${strings.availableFrom}: ${moment(resource.availabilityFrom).format('YYYY-MM-DD')}\n${isMedUsKit ? `${strings.ageInDays}: ${age} ${strings.days}` : ''}`
              : resource && !resource.serialNumber && !isLabsLocation
              ? `${strings.availableFrom}: ${moment(resource.availabilityFrom).format('YYYY-MM-DD')}\n${isMedUsKit ? `${strings.ageInDays}: ${age} ${strings.days}` : ''}`
              : resource && resource.serialNumber && `${strings.serialNumber}: ${resource.serialNumber}`
          }
          className={`slotName ${
            resource?.kitCondition?.kitConditionId === 2 
              ? 'defective-kit' 
              : (!isLabsLocation && !isZMCCCalendar ? 'blue-bg' : '')
          }`}
        >
          {(!isLabsLocation && !isZMCCCalendar) ? <>{slot.slotName}</> : <><Icon name='visibility' width={16} height={16} fill={fillColor} /> {slot.slotName}</> }
        </NavLink>
      </div>
      <div className='d-flex'>
        {(!isLabsLocation && !isZMCCCalendar) ? (
          <p className='info-icon-kit cursor-pointer mr-3 blue-bg'>i</p>
        ) : (
          <p className='input-info cursor-pointer mr-1'>i</p>
        )}
        {!isLabsLocation && (
          <div className='info-message position-absolute'>{infoMessage}</div>
        )}
        <p className={(!isLabsLocation && !isZMCCCalendar) ? 'location-short-name' : 'new-location-short-name'} title={shortName}>
          {shortName}
        </p>
      </div>
    </div>
  );
};

  zmmccSlotTempletResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    return(
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <Checkbox
            // selected={selectedKits.length && selectedKits.includes(slot.slotId)}
            field={slot.slotId}
            onChange={this.onChangeZmccCheckbox}
            small
          />
          <p>{slot.slotName}</p>
          </div>
      </div>
    )
  }

  handleOnChangeOperatorCheckBox = (slot) => {
    const { getSelectedOperatorsCallback } = this.props;
    if(typeof getSelectedOperatorsCallback === 'function' && getSelectedOperatorsCallback ){
      getSelectedOperatorsCallback(slot.slotId);
    }
  }

  zmccOperatorSlotTemplateResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const {selectedOperators} = this.props
    return(
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <Checkbox
            selected={selectedOperators?.length && selectedOperators.includes(slot.slotId)}
            field={slot.slotId}
            onChange={() => this.handleOnChangeOperatorCheckBox(slot)}
            small
          />
          <p>{slot.slotName}</p>
          </div>
      </div>
    )
  }

  onContentScroll = () => {
    const element = document.getElementById('content-wrapper');
    const header = document.getElementById('header-wrapper');
    header.scrollLeft = element.scrollLeft;
    if (element) {
      const currentDate = this.currentMap[Math.floor((element.scrollLeft + window.innerWidth / 8) / 50)];
      if (moment(this.state.currentDate).format('MM') !== moment(currentDate).format('MM')) {
        this.setState({ currentDate });
      }
    }
  };

  onContentScrollToDate = (toIndex = 0) => {
    const element = document.querySelectorAll('#content-wrapper');
    const header = document.querySelectorAll('#header-wrapper');
    header[0].scrollLeft = toIndex;
    element[0].scrollLeft = toIndex;
  }

  /**
   * This is function moves calendar to a particular index
   * @param toIndex index that calendar should move to
   * @description How this works?
   * First elements are taken from DOM like #content-wrapper: In this we will have kits content section
   * and operator, and them scrollLeft(js dom api) function used on them to scroll some pixels
   */
  onZmccContentScrollToDate = (toIndex = 0) => {
    const element = document.querySelectorAll('#content-wrapper');
    const header = document.querySelectorAll('#header-wrapper');
    if(element.length && header.length){
      header[0].scrollLeft = toIndex;
      header[1].scrollLeft = toIndex;
      element[0].scrollLeft = toIndex;
      element[1].scrollLeft = toIndex;
    }

  }

  onZmccContentScroll = () => {
    const element = document.querySelectorAll('#content-wrapper');
    const header = document.querySelectorAll('#header-wrapper');
    this.onZmccContentScrollToDate(element[1].scrollLeft);
    if (element) {
      const currentDate = this.currentMap[Math.floor((element.scrollLeft + window.innerWidth / 8) / 50)];

    }
  }

  handleSelect = id => (value, field) => {
    this.setState(prevState => {
      const selectedKits = [...prevState.selectedKits];
      if (selectedKits.includes(id)) {
        selectedKits.splice(selectedKits.indexOf(id), 1);
      } else {
        selectedKits.push(id);
      }
      return { selectedKits };
    });
  };

  onChangeZmccCheckbox = () => {
    // Functionality will be added soon
  }

  operatorOnScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    schedulerData.next();
    schedulerData.setEvents(this.createOperatorEvents());
    this.setState({
      operatorViewModel: schedulerData
    });

    schedulerContent.scrollLeft = maxScrollLeft - 10;
  }

  onSelectDateForZmcc = (schedulerData, date, operatorViewModel) => {
    const { handleZmccPrevAndNextClick, changeLoader } = this.props
    if (this.state.zmccCurrentViewType === ViewTypes.Day) {
      schedulerData.setMinuteStep(30);
      schedulerData.setDayStartFromAndDayStopTo(8, 18)
      operatorViewModel.setMinuteStep(30);
      operatorViewModel.setDayStartFromAndDayStopTo(8, 18)
    }
    schedulerData.setDate(date);
    let events = [...this.props.events, ...this.props.alreadyBooked];
    events = sortBy(events, item => item.start);
    schedulerData.setEvents(events);
    operatorViewModel.setDate(date);
    operatorViewModel.setEvents(this.createOperatorEvents())
    this.setState({
      viewModel: schedulerData,
      currentDate: date,
      operatorViewModel: operatorViewModel,
    }, () => {
      handleZmccPrevAndNextClick(this.state.currentDate)
    })
}

  setView = (viewType) => {
    this.setState({zmccCurrentViewType: viewType}, () => {
      this.setDataForZmccCalendar(moment().format("YYYY-MM-DD"))
      this.setDataForCalendarOperator(moment().format("YYYY-MM-DD"))
      this.setState({currentDate: moment().format("YYYY-MM-DD")})
    })
  };

zmccPrevClick = (schedulerData, operatorViewModel) => {
  const {changeLoader, handleZmccPrevAndNextClick} = this.props;
  this.onZmccContentScrollToDate(0)
  changeLoader(true);
    schedulerData.prev();
    let events = [...this.props.events, ...this.props.alreadyBooked];
    events = sortBy(events, item => item.start);
    schedulerData.setEvents(events);
    schedulerData.setMinuteStep(30);
    schedulerData.setDayStartFromAndDayStopTo(8, 18)
    operatorViewModel.setDate(schedulerData.startDate);
    operatorViewModel.setEvents(this.createOperatorEvents())
    operatorViewModel.setMinuteStep(30);
    operatorViewModel.setDayStartFromAndDayStopTo(8, 18)
    this.setState({
        viewModel: schedulerData,
        currentDate: schedulerData.startDate,
        operatorViewModel: operatorViewModel,
    }, () => {
      handleZmccPrevAndNextClick(this.state.currentDate)
    });
    setTimeout(() => {
      changeLoader(false);
    }, 500);
}

zmccNextClick = (schedulerData, operatorViewModel) => {
  const {changeLoader, handleZmccPrevAndNextClick} = this.props;
  this.onZmccContentScrollToDate(0)
  changeLoader(true);
    schedulerData.next();
    let events = [...this.props.events, ...this.props.alreadyBooked];
    events = sortBy(events, item => item.start);
    schedulerData.setEvents(events);
    operatorViewModel.setDate(schedulerData.startDate);
    operatorViewModel.setEvents(this.createOperatorEvents())
    schedulerData.setMinuteStep(30);
    schedulerData.setDayStartFromAndDayStopTo(8, 18)
    operatorViewModel.setMinuteStep(30);
    operatorViewModel.setDayStartFromAndDayStopTo(8, 18)
    this.setState({
        viewModel: schedulerData,
        currentDate: schedulerData.startDate,
        operatorViewModel: operatorViewModel,
    }, () => {
      handleZmccPrevAndNextClick(this.state.currentDate)
    })
    setTimeout(() => {
      changeLoader(false);
    }, 500);

}

onSetAddMoreState = (newState) => {
  if (newState === undefined) {
      this.setState({
          headerItem: undefined,
          left: 0,
          top: 0,
          height: 0
      });
  }
  else {
      this.setState({
          ...newState,
      });
  }
}


  render() {

    const { currentDate, viewModel, eventsLoaded, event, operatorViewModel } = this.state;
    const { disableClick, dragable, hideOptions, schedulerWrapperClassName, resizeable, onAddNewBooking, isZMCCCalendar, isCalenderFromAdjustDates, isLabsLocation, isIqsCalendar} = this.props;
    let popover = <div />;
    if (this.state.headerItem !== undefined) {
          popover = <AddMorePopover
          headerItem={this.state.headerItem}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          viewEventText="Ops 1"
          viewEvent2Click={this.ops2}
          viewEvent2Text="Ops 2"
          schedulerData={operatorViewModel}
          closeAction={this.onSetAddMoreState}
          left={this.state.left}
          top={this.state.top}
          height={this.state.height}
          moveEvent={this.moveEvent}
          />
    }

    return (
      <div className='app-schedular-container'>
        <div className='row'>
          <div className='col-md-6'>
            {!this.props.isCalendarFromOperatorSchedules && !this.props?.isIqsCalendar && <RightHeader
              dateToDisplay={currentDate}
              onChangeDate={date => isZMCCCalendar ? this.onSelectDateForZmcc(viewModel, date, operatorViewModel) : this.onSelectDate(viewModel, date, operatorViewModel)}
              zmccPrevClick={isZMCCCalendar ? () =>this.zmccPrevClick(viewModel, operatorViewModel) : () =>this.prevClick(viewModel) }
              zmccNextClick={isZMCCCalendar ? () =>this.zmccNextClick(viewModel, operatorViewModel) :() =>this.nextClick(viewModel)  }
              isZMCCCalendar={isZMCCCalendar}
              viewTypeFromScheduler = {this.state.zmccCurrentViewType}
              viewModel ={viewModel}
              isLabsLocation={isLabsLocation}
            />}
          </div>
         {(isLabsLocation) && <div className='col-md-6'>
            <LeftHeader todayText={strings.todayText} onChangeDate={date => isZMCCCalendar ?this.onSelectDateForZmcc(viewModel, date, operatorViewModel) :this.onSelectDate(viewModel, date, operatorViewModel)} />
          </div>}
          {isZMCCCalendar && !this.props.isCalendarFromOperatorSchedules && <div className='col-md-6 view-styling'>
            <div className='d-flex view-type-btn-container'>
              {/* <Button
                value={strings.days}
                bgFill={false}
                onClick={() => this.setView(ViewTypes.Day)}
              /> */}
              <Button
                styleClass={this.state.zmccCurrentViewType === ViewTypes.Day ? 'view-type-buttons-selected': ''}
                value={strings.day}
                bgFill={false}
                onClick={() => this.setView(ViewTypes.Day)}
              />
              <Button
                styleClass={this.state.zmccCurrentViewType === ViewTypes.WeekDay ? 'view-type-buttons-selected': ''}
                value={strings.week}
                bgFill={false}
                onClick={() => this.setView(ViewTypes.WeekDay)}
              />
              <Button
                styleClass={this.state.zmccCurrentViewType === ViewTypes.Month ? 'view-type-buttons-selected': ''}
                value={strings.month}
                bgFill={false}
                onClick={() => this.setView(ViewTypes.Month)}
              />
            </div>

          </div>}
        </div>
        <div className={schedulerWrapperClassName}>
          {(eventsLoaded && !isZMCCCalendar && !isIqsCalendar) && (
            <>
            <Scheduler
              wrapperElement='app-scheduler-wrapper'
              resizeable={isZMCCCalendar ? false : resizeable}
              schedulerData={viewModel}
              disableClick={disableClick}
              dragable={isZMCCCalendar ? false : dragable}
              hideOptions={hideOptions}
              prevClick={this.prevClick}
              nextClick={this.nextClick}
              onSelectDate={this.onSelectDate}
              eventItemClick={this.eventClicked}
              updateEventStart={this.updateEventStart}
              updateEventEnd={this.updateEventEnd}
              moveEvent={this.moveEvent}
              newEvent={this.newEvent}
              onScrollLeft={this.onScrollLeft}
              onScrollRight={this.onScrollRight}
              onScrollTop={this.onScrollTop}
              onContentScroll={this.onContentScroll}
              onScrollBottom={this.onScrollBottom}
              toggleExpandFunc={this.toggleExpandFunc}
              conflictOccurred={this.conflictOccurred}
              slotItemTemplateResolver={this.slotItemTemplateResolver}
              eventItemTemplateResolver={this.eventItemTemplateResolver}
              eventItemPopoverTemplateResolver={isCalenderFromAdjustDates ? () => <></> : this.eventItemPopoverTemplateResolver}
              nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
              isLabsLocation={this.props.isLabsLocation}
              handleSort={this.props.handleSort}
              sortFields={this.props.sortFields}
              handleChangeForFilter={this.props.handleChangeForFilter}
              listOfDropdown={this.props.listOfDropdown}
              selectedOnFilterCalendar={this.props.selectedOnFilterCalendar}
              isZMCCCalendar={this.props.isZMCCCalendar}
              isOperatorSchedule={false}
            />
          </>
          )}
        </div>
        <div className={schedulerWrapperClassName}>
          {(eventsLoaded && isZMCCCalendar) && (
            <>
            <Scheduler
              // wrapperElement='app-scheduler-wrapper'
              resizeable={isZMCCCalendar ? false : resizeable}
              schedulerData={viewModel}
              disableClick={disableClick}
              dragable={isZMCCCalendar ? false : dragable}
              hideOptions={hideOptions}
              prevClick={this.zmccPrevClick}
              nextClick={this.nextClick}
              onSelectDate={this.onSelectDate}
              eventItemClick={this.eventClicked}
              updateEventStart={this.updateEventStart}
              updateEventEnd={this.updateEventEnd}
              moveEvent={this.moveEvent}
              newEvent={this.newEvent}
              onContentScroll={this.onZmccContentScroll}
              toggleExpandFunc={this.toggleExpandFunc}
              conflictOccurred={this.conflictOccurred}
              slotItemTemplateResolver={this.slotItemTemplateResolver}
              eventItemTemplateResolver={this.eventItemTemplateResolver}
              eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
              nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolverForZmcc}
              isLabsLocation={this.props.isLabsLocation}
              handleSort={this.props.handleSort}
              sortFields={this.props.sortFields}
              handleChangeForFilter={this.props.handleChangeForFilter}
              listOfDropdown={this.props.listOfDropdown}
              selectedOnFilterCalendar={this.props.selectedOnFilterCalendar}
              isZMCCCalendar={true}
              isOperatorSchedule={false}
              viewTypeExt={viewModel.viewType}
              isCalendarFromOperatorSchedules={this.props.isCalendarFromOperatorSchedules}
              zmccSuggesteDate={this.props.startDate}
            />
          </>
          )}
        </div>
        <div className={schedulerWrapperClassName}>
        {eventsLoaded && isZMCCCalendar && <Scheduler
            wrapperElement='app-scheduler-wrapper'
            resizeable={isZMCCCalendar ? false : resizeable}
            schedulerData={operatorViewModel}
            disableClick={disableClick}
            dragable={isZMCCCalendar ? false : dragable}
            hideOptions={hideOptions}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            overlap={true}
            onContentScroll={this.onZmccContentScroll}
            slotItemTemplateResolver={this.zmccOperatorSlotTemplateResolver}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            eventItemPopoverTemplateResolver={this.OperatorPopOver}
            nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolverForZmcc}
            isLabsLocation={this.props.isLabsLocation}
            handleSort={this.props.handleSort}
            sortFields={this.props.sortFields}
            handleChangeForFilter={this.props.handleChangeForFilter}
            listOfDropdown={this.props.listOfDropdown}
            selectedOnFilterCalendar={this.props.selectedOnFilterCalendar}
            isZMCCCalendar={false}
            isOperatorSchedule={true}
            onSetAddMoreState={this.onSetAddMoreState}
            isCalendarFromOperatorSchedules={this.props.isCalendarFromOperatorSchedules}
            zmccSuggesteDate={this.props.startDate}
          />}
        {/* {popover} */}
        </div>
        <MultiSelectKitsPopup
          id='multiSelectKitsPopup'
          saveMultiSelectedKits={this.saveMultiSelectedKits}
          removeSelectedKits={this.removeSelectedKits}
          onAddNewBooking={onAddNewBooking}
          event={event}
        />
      </div>
    );
  }
}

AppScheduler.defaultProps = {
  cart: [],
  disableClick: false,
  dragable: true,
  maxInFuture: 60,
  maxInPast: 60,
  onRejectBooking: () => {},
  onConflictOccurred: () => {},
  onUpdateEvent: () => {},
  onRemoveBookingFromCalendar: () => {},
  onSelectForBooking: () => {},
  onAddNewBooking: () => {},
  onRemoveBooking: () => {},
  resizeable: true,
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default withDragDropContext(connect(mapStateToProps)(AppScheduler));
