import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import { ADD_PREPARATION_PERIOD_MODAL_ID } from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.constants';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import useAddPreparationPeriod from './AddPreparationPeriods.hook';
import $ from 'jquery';
import PopupScheduler from 'library/common/commonComponents/PopupScheduler';

function AddPreparationPeriods({
  id,
  suggestedDate,
  preAndPostPreparationPeriod,
  onChange,
  transactionId,
  handlePreparationPeriodsClose,
}) {
  const {
    headerTemplateResolver,
    selectedDate,
    setSelectedDate,
    event,
    eventItemTemplateResolver,
    handleSave,
    loading,
  } = useAddPreparationPeriod(suggestedDate, preAndPostPreparationPeriod, transactionId);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  return (
    <Modal
      id={`${ADD_PREPARATION_PERIOD_MODAL_ID}`}
      onCloseClick={() => {
        handlePreparationPeriodsClose();
        $(`#${ADD_PREPARATION_PERIOD_MODAL_ID}`).modal("hide")
      }}
      title={strings.addPreparationTime}
      confirmTitle={strings.confirm}
      className={'add-preparation-periods'}
      titleOfCancel={strings.cancel}
      newVersionUi={true}
      confirmAction={handleSave}
      loading={loading}
    >
      <div className='d-flex flex-row justify-content-between'>
        <InputField
          label={strings.prePreprationPeriod}
          field='prePreparationPeriod'
          placeholder=''
          isRequired={false}
          validators={[]}
          value={preAndPostPreparationPeriod.prePreparationPeriod}
          error={preAndPostPreparationPeriod.prePreparationPeriodError}
          onChange={onChange}
          className='w-50 mr-3'
        />
        <InputField
          label={strings.postPreparationPeriod}
          field='postPreparationPeriod'
          placeholder=''
          isRequired={false}
          validators={[]}
          value={preAndPostPreparationPeriod.postPreparationPeriod}
          onChange={onChange}
          className='w-50 ml-3'
          error={preAndPostPreparationPeriod.postPreparationPeriodError}
        />
      </div>
      <PopupScheduler
        shchedulerWidth={750}
        headerTemplateResolver={headerTemplateResolver}
        date={selectedDate}
        handleDateChange={handleDateChange}
        events={event}
        eventItemResolver={eventItemTemplateResolver}
      />
    </Modal>
  );
}

export default React.memo(AddPreparationPeriods);
