import {
  UPDATE_CREATE_SEM_FORM,
  FETCH_CREATE_SEM_TRANSACTION_DETAILS,
  FETCH_LOANS_INTENDED,
  FETCH_BESTWAY_TO_REACH,
  FETCH_DATA_WITHIN,
  FETCH_DATA_WITH_CUSTOMER,
  FETCH_MARKET_SEGMENTS,
  FETCH_RELATIONSHIP_WITH_ZEISS,
  FETCH_STATUS_OF_SAMPLE,
  FETCH_PRIMARY_INSTRUMENT,
  FETCH_ADDITIONAL_INSTRUMENT,
  FETCH_SKILL_LEVEL,
  RESET_CREATE_SEM_FORM,
  FETCH_ALL_UNEXECUTED_LOANS,
  FETCH_ESTIMATE_DAYS,
  FETCH_ZMCC_SHIPPING_ADDRESS,
  FETCH_TRANSACTION_ADDRESS,
  FETCH_MARKET_SUB_SEGMENTS,
  FETCH_SYSTEM_CATEGORIES,
  CLICKED_ADD_IN_DEMO_CENTRE_TAB,
  CLICKED_ADD_IN_TODO_TAB,
  FETCH_SBU_TYPES
} from './SemBooking.constants';

const EMPTY_FORM = {
    formData: {},
    isFormValid: false,
  };
  
export const INITIAL_STATE_SEMBOOKING_REDUCER = {
  schedulingInformationForm: EMPTY_FORM,
  generalInformationForm: EMPTY_FORM,
  projectDetailsForm: EMPTY_FORM,
  customerDetilsForm: EMPTY_FORM,
  primaryPointOfContactForm: EMPTY_FORM,
  customerBackgroundForm: EMPTY_FORM,
  mainApplicationChecklistForm: EMPTY_FORM,
  sampleDeatilsFrom: EMPTY_FORM,
  demoCenterInformationForm: EMPTY_FORM,
  additionalInformationForm: EMPTY_FORM,
  logisticsAndCoordinationForm: EMPTY_FORM,
  todosListForm: EMPTY_FORM,
  kitAttachments: [],
  kitAttachmentsToDelete: [],
  transactionsId: null,
  zmccFullInformationId: null,
  loansIntended: [],
  bestWayToReachData: [],
  dataWithin: [],
  marketSegments: [],
  realtionshipWithZeiss: [],
  statusOfSamples: [],
  dataFromCustomer: [],
  primaryInstrument: [],
  skillLevel: [],
  allUnexecutedLoans: [],
  estimatedays: [],
  shippingAddress: {},
  transactionsAddress: {},
  marketSubSegments: [],
  xbMainApplicationChecklistForm: EMPTY_FORM,
  xbSampleDeatilsFrom: EMPTY_FORM,
  xrmDemoDetailsForm: EMPTY_FORM,
  xrmApplicationInformationForm: EMPTY_FORM,
  xrmSamplesDetailsForm: EMPTY_FORM,
  xrmSampleDescriptionForm: EMPTY_FORM,
  xrmSampleSpecsForm: EMPTY_FORM,
  lmApplicationAndSamplesForm: EMPTY_FORM,
  deleteTodoIds: EMPTY_FORM,
  systemCategoriesList: [],
  onAddMoreClickedINDemoCentreTab: false,
  onAddMorreClickedInTodoTab: false,
  sbuTypes: [],
  isCustomerAttendeeAdded: null,
  isPocAttendeAttede: null,
};

export const CreateSEMFormReducer = (state = INITIAL_STATE_SEMBOOKING_REDUCER, action) => {
  switch (action.type) {
    case UPDATE_CREATE_SEM_FORM:
      return { ...state, ...action.payload };
    case RESET_CREATE_SEM_FORM:
      return { ...INITIAL_STATE_SEMBOOKING_REDUCER };
    case FETCH_CREATE_SEM_TRANSACTION_DETAILS:
      return {
        ...state,
        ...action.payload,
        dataFromCustomer: state.dataFromCustomer,
        transactionsId: action.payload && action.payload.transactions && action.payload.transactions.transactionsId,
        zmccFullInformationId:
          action.payload && action.payload.zmccFullInformationId && action.payload.zmccFullInformationId,
          isCustomerAttendeeAdded: action.payload.isCustomerAttendeeAdded,
          isPOCtAttendeeAdded: action.payload.isPOCtAttendeeAdded
      };
    case FETCH_ALL_UNEXECUTED_LOANS:
      return { ...state, allUnexecutedLoans: action.payload };
    case FETCH_LOANS_INTENDED:
      return { ...state, loansIntended: action.payload };
    case FETCH_BESTWAY_TO_REACH:
      return { ...state, bestWayToReachData: action.payload };
    case FETCH_DATA_WITHIN:
      return { ...state, dataWithin: action.payload };
    case FETCH_MARKET_SEGMENTS:
      return { ...state, marketSegments: action.payload };
    case FETCH_RELATIONSHIP_WITH_ZEISS:
      return { ...state, realtionshipWithZeiss: action.payload };
    case FETCH_STATUS_OF_SAMPLE:
      return { ...state, statusOfSamples: action.payload };
    case FETCH_DATA_WITH_CUSTOMER:
      return { ...state, dataFromCustomer: action.payload };
    case FETCH_PRIMARY_INSTRUMENT:
      return { ...state, primaryInstrument: action.payload };
    case FETCH_ADDITIONAL_INSTRUMENT:
      return { ...state, additionalInstruments: action.payload };
    case FETCH_SKILL_LEVEL:
      return { ...state, skillLevel: action.payload };
    case FETCH_ESTIMATE_DAYS:
      return { ...state, estimatedays: action.payload };
    case FETCH_ZMCC_SHIPPING_ADDRESS:
      return { ...state, shippingAddress: action.payload };
    case FETCH_TRANSACTION_ADDRESS:
      return { ...state, transactionsAddress: action.payload };
    case FETCH_MARKET_SUB_SEGMENTS:
      return { ...state, marketSubSegments: action.payload };
    case FETCH_SYSTEM_CATEGORIES:
      return { ...state, systemCategoriesList: action.payload };
    case CLICKED_ADD_IN_DEMO_CENTRE_TAB:
      return { ...state, onAddMoreClickedINDemoCentreTab: action.payload };
    case CLICKED_ADD_IN_TODO_TAB:
      return { ...state, onAddMorreClickedInTodoTab: action.payload };
    case FETCH_SBU_TYPES:
      return { ...state, sbuTypes: action.payload };
    default:
      return state;
  }
};
  
export default CreateSEMFormReducer;