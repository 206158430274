import React from 'react';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';
import { useStatusOfSample } from './StatusOfSample.hook';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import {  ZMCC_CREATED } from 'library/common/commonConstants/storeConstants';

function StatusOfSample(props) {
  const { handleNonFormComponent, onEditCompleted } = useStatusOfSample(props);
  const { formValue, editCompletedBy, user } = props;
  const { sampleDeatilsFrom } = formValue;
  const isDisSolvedCheckbox =
    sampleDeatilsFrom &&
    sampleDeatilsFrom.formData &&
    (sampleDeatilsFrom.formData.statusOfSampleId === 1 || sampleDeatilsFrom.formData.statusOfSampleId === 2);

  const { zmccTransactionDetails } = props;
  const isBookingCompleted = zmccTransactionDetails && zmccTransactionDetails.zmccSavedStatus===ZMCC_CREATED ? true : false;

  return (
    <div className='p-0 '>
      <Dropdown
        label={strings.statusSamples}
        formName='sampleDeatilsFrom'
        placeholder='Please select'
        idKey='id'
        field='statusOfSampleId'
        data={props.statusOfSamples || []}
        hasSection={false}
        multiSelect={false}
        filter={false}
        isRequired={true}
        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
        displayValue={[{ key: 'name', separator: '' }]}
        onChange={e => handleNonFormComponent(e, 'statusOfSampleId', 'sampleDeatilsFrom')}
        value={sampleDeatilsFrom.formData.statusOfSampleId}
        error={sampleDeatilsFrom.formData.statusOfSampleIdError}
        fullValue={props.statusOfSamples.find(item => item.id === sampleDeatilsFrom.formData.statusOfSampleId)}
      />
      <div className='d-flex align-items-center justify-content-between mt-2'>
        {isDisSolvedCheckbox  && isBookingCompleted && (
          <div className='w-25 p-0 mt-4'>
            <Checkbox
              label={strings.solvedBy}
              field='statusOfSampleSolved'
              isRequired={false}
              selected={sampleDeatilsFrom.formData.statusOfSampleSolved}
              onChange={e => handleNonFormComponent(e, 'statusOfSampleSolved', 'sampleDeatilsFrom')}
              newuiVersionStyle={true}
            />
          </div>
        )}
        {isDisSolvedCheckbox && sampleDeatilsFrom.formData.statusOfSampleSolved && (
          <>
            {!editCompletedBy && (
              <div className='solved-by-edit'>
                {sampleDeatilsFrom.formData.completedByName ? (
                  <p className=' mt-3 ml-4'>{sampleDeatilsFrom.formData.completedByName}</p>
                ) : (
                  <p className=' mt-3 ml-4'>{`${user.firstName} ${user.lastName}`}</p>
                )}
                <div className=' mt-3' onClick={() => onEditCompleted()}>
                  <EditOutlineIcon className='cursor-pointer' />
                </div>
              </div>
            )}
            {editCompletedBy && (
              <div className='w-75 p-0 mt-3 ml-4'>
                <InputField
                  label={''}
                  field='completedByName'
                  formName='sampleDeatilsFrom'
                  placeholder=''
                  isRequired={false}
                  type='text'
                  validators={[]}
                  value={sampleDeatilsFrom.formData.completedByName}
                  error={sampleDeatilsFrom.formData.completedByNameError}
                  onChange={e => handleNonFormComponent(e, 'completedByName', 'sampleDeatilsFrom')}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default StatusOfSample;
