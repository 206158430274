import React, { useEffect, useState } from 'react';
import { timeManagementModel } from './TimeManagement.constants';
import { generateTimeSlots } from 'library/utilities/constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';

export const useTimeManagement = ({
  fetchIqsBookingDropdownData,
  iqsBookingDropdownData,
  user,
  usersList,
  requestId,
  saveTimeManagement,
  fetchTimeManagementList,
  language,
  toggleActionMessage,
}) => {
  const [timeManagementForm, setTimeManagementForm] = useState({ formData: {} });

  useEffect(() => {
    fetchIqsBookingDropdownData();
  }, [language]);

  useEffect(() => {
    setInitalData();
    if (requestId) {
      fetchTimeManagementList(requestId);
    }
  }, [user, requestId]);

  const setInitalData = () => {
    setTimeManagementForm({
      formData: {
        accountId: user?.accountId || '',
        accountIdFullValue: user || null,
        type: '',
        relatedPosition: '10',
        comment: '',
        startTime: '08:00',
        startTimeFullValue: { name: '08:00', staticCode: '08:00' },
        endTime: '16:00',
        endTimeFullValue: { name: '16:00', staticCode: '16:00' },
        breakTime: '0',
      },
    });
  };
  const getTimeManagementModel = () => {
    const model = [...timeManagementModel];
    model[0].options = iqsBookingDropdownData?.iqsRequestCategories || [];
    model[2].options = usersList;
    model[5].options = generateTimeSlots();
    model[6].options = generateTimeSlots();
    return model;
  };

  const handleForm = ({ values, field, formName }) => {
    setTimeManagementForm({
      ...timeManagementForm,
      formData: {
        ...timeManagementForm.formData,
        ...values,
      },
    });
  };

  const onSaveTimeClick = async () => {
    const validForm = validateForm({
      form: timeManagementForm,
      model: getTimeManagementModel(),
    });
    if (!validForm.isFormValid) {
      return setTimeManagementForm(validForm);
    }
    const { type, accountId, startTime, endTime, eventDate, breakTime, comment, relatedPosition } =
      timeManagementForm.formData;
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');

    if (end.isBefore(start)) {
      scrollToTop(500);
      return toggleActionMessage(true, 'error', 'endTimeShouldBeLessThanStartTime');
    }

    const dataToSend = {
      type: type,
      accountId: accountId,
      startTime: startTime,
      endTime: endTime,
      eventDate: moment(eventDate).format('YYYY-MM-DD'),
      breakTime: breakTime,
      comment: comment,
      requestId: requestId,
      relatedPosition: relatedPosition,
    };
    const res = await saveTimeManagement(dataToSend);
    if (res.success) {
      setInitalData();
    }
  };

  const calculateTotalWorkingTime = (startTime, endTime, breakTime) => {
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    const duration = moment.duration(end.diff(start));
    const totalMinutes = duration.asMinutes() - breakTime;
    const sign = totalMinutes < 0 ? '-' : '';
    const absTotalMinutes = Math.abs(totalMinutes);
    const hours = Math.floor(absTotalMinutes / 60);
    const minutes = absTotalMinutes % 60;
    return `${sign} ${hours}h ${minutes}m`;
  };

  const getCols = () => [
    {
      title: strings.scheduleType,
      name: 'type',
      isSort: false,
      render: item => {
        return <span>{item.typeTranslated}</span>;
      },
    },
    {
      title: strings.offerConfirmation,
      name: 'offerConfirmation',
      isSort: false,
      render: item => {
        return <span>{item.relatedPosition}</span>;
      },
    },
    {
      title: strings.date,
      name: 'date',
      isSort: false,
      render: item => {
        return <span>{item.eventDate}</span>;
      },
    },
    {
      title: strings.beginStart,
      name: 'startTime',
      isSort: false,
      render: item => {
        return <span>{moment(item.startTime, 'HH:mm:ss').format('HH:mm')}</span>;
      },
    },
    {
      title: strings.end,
      name: 'endTime',
      isSort: false,
      render: item => {
        return <span>{moment(item.endTime, 'HH:mm:ss').format('HH:mm')}</span>;
      },
    },
    {
      title: strings.break,
      name: 'breakTime',
      isSort: false,
      render: item => {
        return <span>{`${item.breakTime} min`}</span>;
      },
    },
    {
      title: strings.sum,
      name: 'breakTime',
      isSort: false,
      render: item => {
        return <span>{calculateTotalWorkingTime(item.startTime, item.endTime, item.breakTime)}</span>;
      },
    },
    {
      title: strings.person,
      name: 'person',
      isSort: false,
      render: item => {
        return <span>{`${item?.person?.lastName}, ${item?.person?.firstName}`}</span>;
      },
    },
    {
      title: strings.commentText,
      name: 'comment',
      isSort: false,
      render: item => {
        return <span>{item.comment}</span>;
      },
    },
  ];
  const cols = getCols();
  return {
    getTimeManagementModel,
    handleForm,
    timeManagementForm,
    onSaveTimeClick,
    cols,
  };
};
