import Validators from 'library/utilities/Validators';

export const sellOffModel = [
  {
    label: 'activateSellOffFunction',
    selected: false,
    type: 'checkbox',
    field: 'sellStatus',
    required: false,
    styleClass: 'col-sm-12',
    newuiVersionStyle: true,
  },
  {
    label: 'externalBorrowerCanSeePlatform',
    selected: false,
    type: 'checkbox',
    field: 'borrowerViewStatus',
    required: false,
    styleClass: 'col-sm-12',
    newuiVersionStyle: true,
  },
  {
    label: 'kitViewDuration',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'kitViewDuration',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  }
];

export const addressModel = [
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'number',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'number',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20
    }
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20
    }
  },
  {
    label: 'state',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'stateName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      { check: Validators.required, message: 'requiredErrorMessage' },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
];
